import React from 'react';
import { Box,Typography } from '@mui/material';
import ClientTable from './ClientsSideNav/ClientsSideNav';
import ReactTable from './Dashboard/Dashboard'

const ClientListDetails = () => {

  return (
    <Box sx={{ display: 'flex' }}>
      {/* <LeftSidenav /> */}
      <ClientTable />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <ReactTable />
      </Box>
    </Box>
  );
};

export default ClientListDetails;
