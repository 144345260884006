// import React, { useState } from 'react';
// import {
//  Box,
//  IconButton,
//  Typography,
//  List,
//  ListItem,
//  ListItemText,
//  Divider,
//  Checkbox,
// } from '@mui/material';
// import { ChevronLeft, ChevronRight } from '@mui/icons-material';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import { useNavigate } from 'react-router-dom'; // Import useNavigate
// import './RightNav.css';


// const priorities = [
//  "Income Replacement",
//  "Mortgage Protection",
//  "College Funding for Jonathan",
//  "Retirement Income",
//  "Funeral Costs",
//  "Living Benefits",
//  "Child's Policy for Jonathan",
//  "Child's Policy for Beth",
// ];


// const reviewItems = [
//  "Send change of beneficiary form to update the policy",
//  "Look for a new policy on Johnny (age 7)",
//  "Regroup in July for a follow up",
//  "Check underwriting on Judy's policy",
// ];


// const RightNav = () => {
//  const [open, setOpen] = useState(true);
//  const [checkedPriorities, setCheckedPriorities] = useState(Array(priorities.length).fill(false));
//  const [checkedReviewItems, setCheckedReviewItems] = useState(Array(reviewItems.length).fill(false));
//  const clientData = JSON.parse(localStorage.getItem('client'));
//  const userId = clientData.user_id;
//   const navigate = useNavigate(); // Initialize useNavigate


//  const toggleNav = () => {
//    setOpen(!open);
//  };


//  const handleTogglePriority = (index) => {
//    const newChecked = [...checkedPriorities];
//    newChecked[index] = !newChecked[index];
//    setCheckedPriorities(newChecked);
//  };


//  const handleToggleReviewItem = (index) => {
//    const newChecked = [...checkedReviewItems];
//    newChecked[index] = !newChecked[index];
//    setCheckedReviewItems(newChecked);
//  };


//  const handleNavigateToForm = () => {
//    navigate(`/editformInfo/${userId}`); // Navigate to the EditFormInfo route
//  };


//  return (
//    <Box
//      sx={{
//        width: open ? 350 : 60,
//        height: "100vh",
//        transition: "width 0.3s",
//        bgcolor: "background.paper",
//        boxShadow: 1,
//        position: "relative",
//      }}
//    >
//      <IconButton
//        onClick={toggleNav}
//        sx={{
//          position: "absolute",
//          top: "50%",
//          left: -20,
//          transform: "translateY(-50%)",
//          background: "#e4e6eb",
//        }}
//      >
//        {open ? <ChevronRight /> : <ChevronLeft />}
//      </IconButton>
//      {open && (
//        <Box sx={{ padding: 2 }}>
//          <Box sx={{ textAlign: 'right', py: 1 }}>
//            <IconButton onClick={handleNavigateToForm}>
//              <AccountCircleIcon style={{ fontSize: 50 }} />
//            </IconButton>
//          </Box>
//          <Typography variant="h6" className="priorStyle">
//            PRIORITIES
//          </Typography>
//          <Divider sx={{ mx: 3 }} />
//          <List sx={{ px: 0 }}>
//            {priorities.map((text, index) => (
//              <ListItem key={index} dense button onClick={() => handleTogglePriority(index)}>
//                <Checkbox
//                  checked={checkedPriorities[index]}
//                  onChange={() => handleTogglePriority(index)}
//                  sx={{
//                    marginRight: 1,
//                    boxShadow: '3px 3px 1px gray',
//                    border: 'none',
//                    borderRadius: 0,
//                    width: 16,
//                    height: 16,
//                    color: 'gray',
//                    backgroundColor: 'transparent',
//                    '& .MuiSvgIcon-root': {
//                      fill: 'gray',
//                    },
//                  }}
//                />
//                <ListItemText primary={text} sx={{ marginLeft: 2 }} />
//              </ListItem>
//            ))}
//          </List>
//          <Typography variant="h6" className="priorStyle">
//            REVIEW ITEMS
//          </Typography>
//          <Divider sx={{ mx: 3 }} />
//          <List>
//            {reviewItems.map((text, index) => (
//              <ListItem key={index} dense button onClick={() => handleToggleReviewItem(index)}>
//                <Checkbox
//                  checked={checkedReviewItems[index]}
//                  onChange={() => handleToggleReviewItem(index)}
//                  sx={{
//                    marginRight: 1,
//                    boxShadow: '3px 3px 1px gray',
//                    border: 'none',
//                    borderRadius: 0,
//                    width: 16,
//                    height: 16,
//                    color: 'gray',
//                    backgroundColor: 'transparent',
//                    '& .MuiSvgIcon-root': {
//                      fill: 'gray',
//                    },
//                  }}
//                />
//                <ListItemText primary={text} sx={{ marginLeft: 2 }} />
//              </ListItem>
//            ))}
//          </List>
//        </Box>
//      )}
//    </Box>
//  );
// };


// export default RightNav;








import React, { useState } from 'react';
import {
 Box,
 IconButton,
 Typography,
 List,
 ListItem,
 ListItemText,
 Divider,
 Checkbox,
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './RightNav.css';


const priorities = [
 "Income Replacement",
 "Mortgage Protection",
 "College Funding for Jonathan",
 "Retirement Income",
 "Funeral Costs",
 "Living Benefits",
 "Child's Policy for Jonathan",
 "Child's Policy for Beth",
];


const reviewItems = [
 "Send change of beneficiary form to update the policy",
 "Look for a new policy on Johnny (age 7)",
 "Regroup in July for a follow up",
 "Check underwriting on Judy's policy",
];


const RightNav = () => {
 const [open, setOpen] = useState(true);
 const [checkedPriorities, setCheckedPriorities] = useState(Array(priorities.length).fill(false));
 const [checkedReviewItems, setCheckedReviewItems] = useState(Array(reviewItems.length).fill(false));
 const clientData = JSON.parse(localStorage.getItem('client'));
 const userId = clientData.user_id;
  const navigate = useNavigate(); // Initialize useNavigate


 const toggleNav = () => {
   setOpen(!open);
 };


 const handleTogglePriority = (index) => {
   const newChecked = [...checkedPriorities];
   newChecked[index] = !newChecked[index];
   setCheckedPriorities(newChecked);
 };


 const handleToggleReviewItem = (index) => {
   const newChecked = [...checkedReviewItems];
   newChecked[index] = !newChecked[index];
   setCheckedReviewItems(newChecked);
 };


 const handleNavigateToForm = () => {
   navigate(`/editformInfo/${userId}`); // Navigate to the EditFormInfo route
 };


 return (
   <Box
     sx={{
       width: open ? 350 : 60,
       height: "100vh",
       transition: "width 0.3s",
       bgcolor: "background.paper",
       boxShadow: 1,
       position: "relative",
     }}
   >
     <IconButton
       onClick={toggleNav}
       sx={{
         position: "absolute",
         top: "50%",
         left: -20,
         transform: "translateY(-50%)",
         background: "#e4e6eb",
       }}
     >
       {open ? <ChevronRight /> : <ChevronLeft />}
     </IconButton>
     {open && (
       <Box sx={{ padding: 2 }}>
         <Box sx={{ textAlign: 'right', py: 1 }}>
           <IconButton onClick={handleNavigateToForm}>
             <AccountCircleIcon style={{ fontSize: 50 }} />
           </IconButton>
         </Box>
         <Typography variant="h6" className="priorStyle">
           PRIORITIES
         </Typography>
         <Divider sx={{ mx: 3 }} />
         <List sx={{ px: 0 }}>
           {priorities.map((text, index) => (
             <ListItem key={index} dense button onClick={() => handleTogglePriority(index)}>
               <Checkbox
                 checked={checkedPriorities[index]}
                 onChange={() => handleTogglePriority(index)}
                 sx={{
                   marginRight: 1,
                   boxShadow: '3px 3px 1px gray',
                   border: 'none',
                   borderRadius: 0,
                   width: 16,
                   height: 16,
                   color: 'gray',
                   backgroundColor: 'transparent',
                   '& .MuiSvgIcon-root': {
                     fill: 'gray',
                   },
                 }}
               />
               <ListItemText primary={text} sx={{ marginLeft: 2 }} />
             </ListItem>
           ))}
         </List>
         <Typography variant="h6" className="priorStyle">
           REVIEW ITEMS
         </Typography>
         <Divider sx={{ mx: 3 }} />
         <List>
           {reviewItems.map((text, index) => (
             <ListItem key={index} dense button onClick={() => handleToggleReviewItem(index)}>
               <Checkbox
                 checked={checkedReviewItems[index]}
                 onChange={() => handleToggleReviewItem(index)}
                 sx={{
                   marginRight: 1,
                   boxShadow: '3px 3px 1px gray',
                   border: 'none',
                   borderRadius: 0,
                   width: 16,
                   height: 16,
                   color: 'gray',
                   backgroundColor: 'transparent',
                   '& .MuiSvgIcon-root': {
                     fill: 'gray',
                   },
                 }}
               />
               <ListItemText primary={text} sx={{ marginLeft: 2 }} />
             </ListItem>
           ))}
         </List>
       </Box>
     )}
   </Box>
 );
};


export default RightNav;