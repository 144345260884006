import React from 'react';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import LeftSidenav from './LeftSidenav/LeftSidenav';
import FormInfo from './IntakeForm/FormInfo';
import UpdateFormInfo from './IntakeForm/EditFormInfo';

const IntakeDetails = () => {
  const location = useLocation();
  const clientData = JSON.parse(localStorage.getItem('client'));
  const clientId = clientData.user_id;
  

  // Determine which component to render based on the pathname
  const renderComponent = () => {
    if (location.pathname === `/editformInfo/${clientId}`) {
      return <UpdateFormInfo />;
    }
    if (location.pathname === '/create') {
      return <FormInfo />;
    }
    return;
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <LeftSidenav />
      <Box component="main" sx={{ flexGrow: 1, p: 3, overflowY: 'auto' }}>
        {renderComponent()}
      </Box>
    </Box>
  );
};

export default IntakeDetails;