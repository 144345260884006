// src/Header.js
import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';

export default function Header({userEmail}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // Implement logout functionality here
    handleMenuClose();
    navigate('/');
  };

  return (
    <AppBar position="fixed" style={{ backgroundColor: '#2296f2' }}>
      <Toolbar style={{ justifyContent: 'flex-end' }}>
        <IconButton edge="end" color="inherit" onClick={handleMenuOpen}>
          <AccountCircleIcon style={{ fontSize: 40 }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem>
            <Typography>{userEmail}</Typography>
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <Typography>Logout</Typography>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
