import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Header from '../HeadingList/HeadingList';
import DeleteIcon from "@mui/icons-material/Delete";
import './FormDesign.css';

const FutureIncome = ({ onDataChange, initialData }) => {
  const [data, setData] = useState({
    inheritance: [{ name: '', balance: '', incomeReplacement: false }],
    annuities: [{ name: '', balance: '', annuity: false }],
    socialSecurity: [{ name: '', monthly: '', annual: '', mortageProtection: false }],
    other: [{ name: '', monthly: '', accidentalDeathBenefit: false }],
  });

  useEffect(() => {
    if (initialData) {
      setData({
        inheritance: initialData.inheritance.map(item => ({
          name: item.name || '',
          balance: item.balance || '',
          incomeReplacement: item.incomeReplacement === 'Yes',
        })),
        annuities: initialData.annuities.map(item => ({
          name: item.name || '',
          balance: item.balance || '',
          annuity: item.annuity === 'Yes',
        })),
        socialSecurity: initialData.socialSecurity.map(item => ({
          name: item.name || '',
          monthly: item.monthly || '',
          annual: item.annual || '',
          mortageProtection: item.mortgageProtection === 'Yes',
        })),
        other: initialData.other.map(item => ({
          name: item.name || '',
          monthly: item.monthly || '',
          accidentalDeathBenefit: item.accidentalDeathBenefit === 'Yes',
        })),
      });
    }
  }, [initialData]);

  useEffect(() => {
    const payload = {
      inheritance: data.inheritance.map(item => ({
        name: item.name,
        balance: parseFloat(item.balance) || '',
        incomeReplacement: item.incomeReplacement ? 'Yes' : 'No',
      })),
      annuities: data.annuities.map(item => ({
        name: item.name,
        balance: parseFloat(item.balance) || '',
        annuity: item.annuity ? 'Yes' : 'No',
      })),
      socialSecurity: data.socialSecurity.map(item => ({
        name: item.name,
        monthly: parseFloat(item.monthly) || '',
        annual: parseFloat(item.annual) || '',
        mortageProtection: item.mortageProtection ? 'Yes' : 'No',
      })),
      other: data.other.map(item => ({
        name: item.name,
        monthly: parseFloat(item.monthly) || '',
        accidentalDeathBenefit: item.accidentalDeathBenefit ? 'Yes' : 'No',
      })),
    };

    onDataChange(payload);
  }, [data, onDataChange]);

  const handleChange = (e, index, category, name) => {
    const { value, type, checked } = e.target;
  
    setData((prevData) => ({
      ...prevData,
      [category]: Array.isArray(prevData[category])
        ? prevData[category].map((item, i) =>
            i === index
              ? { ...item, [name]: type === "checkbox" ? checked : value }
              : item
          )
        : {
            ...prevData[category],
            [name]: type === "checkbox" ? checked : value,
          }, // If it's an object, update it directly
    }));
  };  

  const handleCheckboxChange = (e, index, category, name) => {
    const { checked } = e.target;
  
    setData((prevData) => ({
      ...prevData,
      [category]: prevData[category].map((item, i) =>
        i === index ? { ...item, [name]: checked } : item
      ),
    }));
  };
  

  const handleAdd = (type) => {
    const newEntry = {
      name: '',
      balance: '',
      incomeReplacement: false,
      annuity: false,
      monthly: '',
      annual: '',
      mortageProtection: false,
      accidentalDeathBenefit: false,
    };

    setData(prevData => ({
      ...prevData,
      [type]: [...prevData[type], newEntry],
    }));
  };

  const deleteEntry = (index, type) => {
    setData(prevData => ({
      ...prevData,
      [type]: prevData[type].filter((_, i) => i !== index),
    }));
  };

  return (
    <>
      <Header name="FUTURE ASSETS / INCOME" />

      <Box className="mt-1" sx={{ padding: 2,marginBottom: 6 }}>
        {/* Inheritance Section */}

        {data.inheritance.map((item, index) => (
          <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1,flexWrap:'nowrap' }} key={index}>
            <Grid  xs={12} sm={2} className="responsiveWidth">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton sx={{padding:'1px',color:'#24527B'}} onClick={() => handleAdd('inheritance')}>
                  <AddIcon className="icons" />
                </IconButton>
                <Typography variant="body1" className="spouseTypo">
                  Inheritance
                </Typography>
              </Box>
            </Grid>

            <div className="grid-container">
              <div className="grid-item">
                <input
                  type="text"
                  placeholder="Name"
                  value={item.name}
                  onChange={(e) =>
                    handleChange(e, index, 'inheritance', 'name')
                  }
                />
              </div>

              <div className="grid-item">
                <input
                  type="text"
                  placeholder="Balance"
                  value={item.balance}
                  onChange={(e) =>
                    handleChange(e, index, 'inheritance', 'balance')
                  }
                />
              </div>

              {/* Add Checkbox */}
              <div className="grid-item checkItem">
                <FormControlLabel
                  sx={{ whiteSpace: "nowrap" }}
                  control={
                    <Checkbox
                      checked={item.incomeReplacement}
                      onChange={(e) =>
                        handleCheckboxChange(e, index, 'inheritance', 'incomeReplacement')
                      }
                      sx={{
                      marginRight: 2,
                      boxShadow: '3px 3px 1px gray',
                      border: 'none',
                      borderRadius: 0,
                      width: 16,
                      height: 16,
                      color: 'gray',
                      backgroundColor: 'transparent',
                      '& .MuiSvgIcon-root': {
                        fill: 'gray',
                      },
                    }}
                    />
                  }
                  label="Income Replacement"
                />
                {data.inheritance.length > 1 && (
                  <IconButton color="#EFEFEF" className="iconFix" onClick={() => deleteEntry(index, 'inheritance')}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            </div>
          </Grid>
        ))}


        {/* Annuities Section */}

      {data.annuities.map((item, index) => (
        <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1,flexWrap:'nowrap' }} key={index}>
          <Grid  xs={12} sm={2} className="responsiveWidth">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton sx={{padding:'1px',color:'#24527B'}} onClick={() => handleAdd('annuities')}>
                <AddIcon className="icons" />
              </IconButton>
              <Typography variant="body1" className="spouseTypo">
                Annuities
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
            <div className="grid-item">
              <input
                type="text"
                placeholder="Name"
                value={item.name}
                onChange={(e) =>
                  handleChange(e,index, 'annuities','name')
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Balance"
                value={item.balance}
                onChange={(e) =>
                  handleChange(e,index, 'annuities','balance')
                }
              />
            </div>

            {/* Add Checkbox */}
            <div className="grid-item checkItem">
              <FormControlLabel
                sx={{whiteSpace:"nowrap"}}
                control={
                  <Checkbox
                    checked={item.annuity}
                    onChange={(e) =>
                      handleCheckboxChange(e, index, 'annuities', 'annuity')
                    }
                    sx={{
                      marginRight: 2,
                      boxShadow: '3px 3px 1px gray',
                      border: 'none',
                      borderRadius: 0,
                      width: 16,
                      height: 16,
                      color: 'gray',
                      backgroundColor: 'transparent',
                      '& .MuiSvgIcon-root': {
                        fill: 'gray',
                      },
                    }}
                  />
                  
                }
                label="Annuity"
              />
              {data.annuities.length > 1 && (
                  <IconButton color="#EFEFEF" className="iconFix" onClick={() => deleteEntry(index, 'annuities')}>
                    <DeleteIcon />
                  </IconButton>
                )}
            </div>
          </div>
        </Grid>
      ))}


        {/* Social Security Section */}

      {data.socialSecurity.map((item, index) => (
        <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1,flexWrap:'nowrap' }} key={index}>
          <Grid  xs={12} sm={2} className="responsiveWidth">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton sx={{padding:'1px',color:'#24527B'}} onClick={() => handleAdd('socialSecurity')}>
                <AddIcon className="icons" />
              </IconButton>
              <Typography variant="body1" className="spouseTypo">
              Social Security
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
            <div className="grid-item">
              <input
                type="text"
                placeholder="Name"
                value={item.name}
                onChange={(e) =>
                  handleChange(e,index, 'socialSecurity','name')
                  // handleChange(index, 'socialSecurity', e.target.value, 'name')
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Monthly"
                value={item.monthly}
                onChange={(e) =>
                  handleChange(e,index, 'socialSecurity', 'monthly')
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Annual"
                value={item.annual}
                onChange={(e) =>
                  handleChange(e,index, 'socialSecurity', 'annual')
                }
              />
            </div>

            {/* Add Checkbox */}
            <div className="grid-item checkboxItem">
              <FormControlLabel
                sx={{whiteSpace:"nowrap"}}
                control={
                  <Checkbox
                    checked={item.mortageProtection}
                    onChange={(e) =>
                      // handleChange(index, 'socialSecurity', e.target.checked)
                      handleCheckboxChange(e, index, 'socialSecurity', 'mortageProtection')
                    }
                    sx={{
                      marginRight: 2,
                      boxShadow: '3px 3px 1px gray',
                      border: 'none',
                      borderRadius: 0,
                      width: 16,
                      height: 16,
                      color: 'gray',
                      backgroundColor: 'transparent',
                      '& .MuiSvgIcon-root': {
                        fill: 'gray',
                      },
                    }}
                  />
                  
                }
                label="Mortgage Protection"
              />
              {data.socialSecurity.length > 1 && (
                  <IconButton color="#EFEFEF" className="iconFix" onClick={() => deleteEntry(index, 'socialSecurity')}>
                    <DeleteIcon />
                  </IconButton>
                )}
            </div>
          </div>
        </Grid>
      ))}

        {/* Other Section */}

        {data.other.map((item, index) => (
        <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1,flexWrap:'nowrap' }} key={index}>
          <Grid  xs={12} sm={2} className="responsiveWidth">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton sx={{padding:'1px',color:'#24527B'}} onClick={() => handleAdd('other')}>
                <AddIcon className="icons" />
              </IconButton>
              <Typography variant="body1" className="spouseTypo">
                  Other
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
            <div className="grid-item">
              <input
                type="text"
                placeholder="Name"
                value={item.name}
                onChange={(e) =>
                  handleChange(e,index, 'other','name')
                  // handleChange(index, 'other', e.target.value, 'name')
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Monthly"
                value={item.monthly}
                onChange={(e) =>
                  handleChange(e,index, 'other', 'monthly')
                }
              />
            </div>

            {/* Add Checkbox */}
            <div className="grid-item checkItem">
              <FormControlLabel
                sx={{whiteSpace:"nowrap"}}
                control={
                  <Checkbox
                    checked={item.accidentalDeathBenefit}
                    onChange={(e) =>
                      handleCheckboxChange(e,index, 'other','accidentalDeathBenefit' )
                    }
                    sx={{
                      marginRight: 2,
                      boxShadow: '3px 3px 1px gray',
                      border: 'none',
                      borderRadius: 0,
                      width: 16,
                      height: 16,
                      color: 'gray',
                      backgroundColor: 'transparent',
                      '& .MuiSvgIcon-root': {
                        fill: 'gray',
                      },
                    }}
                  />
                  
                }
                label="Accidental Death Benefit"
              />
              {data.other.length > 1 && (
                  <IconButton color="#EFEFEF" className="iconFix" onClick={() => deleteEntry(index, 'other')}>
                    <DeleteIcon />
                  </IconButton>
                )}
            </div>
          </div>
        </Grid>
      ))}
      </Box>
    </>
  );
};

export default FutureIncome;
