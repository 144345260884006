



import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import './FormDesign.css';
import Header from '../HeadingList/HeadingList'; // Adjust the path as needed
import DeleteIcon from '@mui/icons-material/Delete';

const   FamilyData = ({ onDataChange, initialData }) => {
  const [data, setData] = useState({
    spouses: [{ name: '', age: '', lifeInsurance: false }],
    children: [{ name: '', age: '', lifeInsurance: false }],
    houseBill: '',
    familyHistory: '',
    livingBenefits: false,
  });

  useEffect(() => {
    if (initialData) {
      setData({
        spouses: initialData.spouseFamily.map(spouse => ({
          name: spouse.name || '',
          age: spouse.age || '',
          lifeInsurance: spouse.lifeInsurancePolicy === 'Yes',
        })),
        children: initialData.childFamily.map(child => ({
          name: child.name || '',
          age: child.age || '',
          lifeInsurance: child.lifeInsurancePolicy === 'Yes',
        })),
        houseBill: initialData.householdBills || '',
        familyHistory: initialData.parentsOrGrandparents.yesOrNo || '',
        livingBenefits: initialData.parentsOrGrandparents.livingBenefits === 'Yes',
      });
    }
  }, [initialData]);

  useEffect(() => {
    const payload = {
      spouseFamily: data.spouses.map(spouse => ({
        name: spouse.name,
        age: spouse.age ? parseInt(spouse.age, 10) : '',
        lifeInsurancePolicy: spouse.lifeInsurance ? 'Yes' : 'No',
      })),
      childFamily: data.children.map(child => ({
        name: child.name,
        age: child.age ? parseInt(child.age, 10) : '',
        lifeInsurancePolicy: child.lifeInsurance ? 'Yes' : 'No',
      })),
      householdBills: data.houseBill ? parseFloat(data.houseBill) : '',
      parentsOrGrandparents: {
        yesOrNo: data.familyHistory,
        livingBenefits: data.livingBenefits ? 'Yes' : 'No',
      },
    };

    onDataChange(payload);
  }, [data, onDataChange]);

  const handleChange = (e, index, category, name) => {
    const { value, type, checked } = e.target;
  
    setData((prevData) => ({
      ...prevData,
      [category]: Array.isArray(prevData[category])
        ? prevData[category].map((item, i) =>
            i === index
              ? { ...item, [name]: type === "checkbox" ? checked : value }
              : item
          )
        : (type === "checkbox" ? checked : value),
    }));
  };
  
  const handleCheckboxChange = (e, index, category, name) => {
    const { checked } = e.target;
  
    setData((prevData) => ({
      ...prevData,
      [category]: prevData[category].map((item, i) =>
        i === index ? { ...item, [name]: checked } : item
      ),
    }));
  };

  const addNewEntry = (type) => {
    setData(prevData => ({
      ...prevData,
      [type]: [...prevData[type], { name: '', age: '', lifeInsurance: false }],
    }));
  };

  const deleteEntry = (index, type) => {
    setData(prevData => ({
      ...prevData,
      [type]: prevData[type].filter((_, i) => i !== index),
    }));
  };

  return (
    <>
      <Header name="FAMILY / BACKGROUND" isPriority  className="headerSpace"/>

      <Box className="mt-1" sx={{ marginBottom: 6,padding: 2 }}>
        {/* Spouse Section */}
      {data.spouses.map((spouse, index) => (
        <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1 ,flexWrap:'nowrap'}} key={index}>
          <Grid xs={12} sm={2} className="responsiveWidth">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* <IconButton color="primary" onClick={() => addNewEntry('spouses')}>
                <AddIcon className="icon" />
              </IconButton> */}
              <Typography variant="body1" className="list-1">
                Spouse
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
            <div className="grid-item">
              <input
                type="text"
                placeholder="First Name"
                value={spouse.name}
                onChange={(e) =>
                  handleChange(e,index, 'spouses', 'name')
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Age"
                value={spouse.age}
                onChange={(e) =>
                  handleChange(e,index, 'spouses', 'age')
                }
              />
            </div>

            {/* Add Checkbox */}
            <div className="grid-item checkItem">
              <FormControlLabel sx={{whiteSpace:'nowrap'}}
                control={
                  <Checkbox
                    checked={spouse.lifeInsurance}
                    onChange={(e) =>

                      handleCheckboxChange(e,index, 'spouses', 'lifeInsurance')
                    }
                    sx={{
                      marginRight: 2,
                      boxShadow: '3px 3px 1px gray',
                      border: 'none',
                      borderRadius: 0,
                      width: 10,
                      height: 10,
                      color: 'gray',
                      backgroundColor: 'transparent',
                      '& .MuiSvgIcon-root': {
                        fill: 'gray',
                      },
                    }}
                  />
                  
                }
                label="Life Insurance Policy"
              />
              {data.spouses.length > 1 && (
                <IconButton color="#EFEFEF" className="iconFix" onClick={() => deleteEntry(index, 'spouses')}>
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          </div>
        </Grid>
      ))}


      {data.children.map((child, index) => (
        <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1 ,flexWrap:'nowrap'}} key={index}>
          <Grid xs={12} sm={2} className="responsiveWidth">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton sx={{padding:'1px',color:'#24527B'}} onClick={() => addNewEntry('children')}>
                <AddIcon className="icons" />
              </IconButton>
              <Typography variant="body1" className="spouseTypo">
                Child
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
            <div className="grid-item">
              <input
                type="text"
                placeholder="Name"
                value={child.name}
                onChange={(e) =>
                  handleChange(e,index, 'children', 'name')
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Age"
                value={child.age}
                onChange={(e) =>
                  handleChange(e,index, 'children', 'age')
                }
              />
            </div>

            {/* Add Checkbox */}
            <div className="grid-item checkItem">
              <FormControlLabel sx={{whiteSpace:'nowrap'}}
                control={
                  <Checkbox
                    checked={child.lifeInsurance}
                    onChange={(e) =>
                      // handleCheckboxChange(index, 'children', e.target.checked)
                      handleCheckboxChange(e,index, 'children', 'lifeInsurance')
                    }
                    sx={{
                      marginRight: 2,
                      boxShadow: '3px 3px 1px gray',
                      border: 'none',
                      borderRadius: 0,
                      width: 16,
                      height: 16,
                      color: 'gray',
                      backgroundColor: 'transparent',
                      '& .MuiSvgIcon-root': {
                        fill: 'gray',
                      },
                    }}
                  />
                  
                }
                label="Life Insurance Policy"
              />
              {data.children.length > 1 && (
                <IconButton color="#EFEFEF" className="iconFix" onClick={() => deleteEntry(index, 'children')}>
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          </div>
        </Grid>
      ))}

        {/* House Bill Section */}
        <Grid container spacing={2} alignItems="center" className='houseCss' sx={{mt: 1,flexWrap:'nowrap'}}>
          <Grid  xs={12} md={2}>
            <Typography variant="body1" component="span" className="list">
              Household Bills (total) 
            </Typography>
          </Grid>
  
          <div className="grid-container">
            <div className="grid-item">
              <input
                type="text"
                placeholder="Amount"
                value={data.houseBill}
                onChange={(e) =>
                  handleChange(e, 0, 'houseBill','houseBill' )
                }
              />
            </div>       
          </div>
        </Grid>

        {/* Family History Section */}
        <Grid container spacing={2} alignItems="center" className='historyCss' sx={{ mt: 1, mb: 5,display:'flex',flexWrap:'nowrap' }}>
          <Grid xs={12} md={2}>
            <Typography variant="body1" component="span" className="typoAlign">
              Parents or Grandparents History of Cancer, Heart Attack, Stroke, or Diabetes?
            </Typography>
          </Grid>
          <div className="grid-container">
            <div className="grid-item">
              <input
                type="text"
                placeholder="Y/N"
                value={data.familyHistory}
                onChange={(e) =>
                  handleChange(e, 0,'familyHistory', 'y/n')
                }
              />
            </div>


            {/* Add Checkbox */}
            <div className="grid-item checkItem">
              <FormControlLabel
              sx={{whiteSpace:'nowrap'}}
                control={
                  <Checkbox
                    checked={data.livingBenefits}
                    onChange={(e) =>
                      handleChange( e,'familyHistory','livingBenefits')
                    }
                    sx={{
                      marginRight: 2,
                      boxShadow: '3px 3px 1px gray',
                      border: 'none',
                      borderRadius: 0,
                      width: 16,
                      height: 16,
                      color: 'gray',
                      backgroundColor: 'transparent',
                      '& .MuiSvgIcon-root': {
                        fill: 'gray',
                      },
                    }}
                  />
                  
                }
                label="Living Benefits"
              />
            </div>
          </div>
        </Grid>
      </Box>
    </>
  );
};

export default FamilyData;
