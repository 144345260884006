import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  IconButton,
  Checkbox,
  Button,
  TextField, InputAdornment
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useLocation } from "react-router-dom";
import "./LeftSidenav.css";
import { ClientContext } from "../../context/ClientContext";
import api from "../../api/tokenCode";
import SearchIcon from '@mui/icons-material/Search';

const LeftSidenav = ({ data }) => {
  const location = useLocation();
  const [debtData, setDebtData] = useState(data || location.state?.debtData);
  const [termData, setTermData] = useState(data || location.state?.termData);
  const [wholeData, setWholeData] = useState(data || location.state?.wholeData);
  const [finalData, setFinalData] = useState(data || location.state?.finalData);
  const clientData = JSON.parse(localStorage.getItem("client"));
  const userId = clientData.user_id;


  // const termValues = termData[0].clientId;
  // console.log(termValues,"yshgcf");
  const { client } = useContext(ClientContext);
  const [isShown, setIsShown] = useState(false);
  const navigate = useNavigate();
  const [localClient, setLocalClient] = useState(client);
  const [isShownDebate, setIsShownDebate] = useState(false);

  // State to track which debts are checked
  const [checkedDebts, setCheckedDebts] = useState([]);

  const [termCoverage, setTermCoverage] = useState([]);
  const [wholeCoverage, setWholeCoverage] = useState([]);
  const [finalCoverage, setFinalCoverage] = useState([]);

  const [selectedFinalIds, setSelectedFinalIds] = useState([]);

  const handleCheckboxChangeterm = (termId) => {
    setSelectedFinalIds((prevSelectedFinalIds) =>
      prevSelectedFinalIds.includes(termId)
        ? prevSelectedFinalIds.filter((id) => id !== termId)
        : [...prevSelectedFinalIds, termId]
    );
  };

  const handleCheckboxChangewhole = (wholeId) => {
    setSelectedFinalIds((prevSelectedFinalIds) =>
      prevSelectedFinalIds.includes(wholeId)
        ? prevSelectedFinalIds.filter((id) => id !== wholeId)
        : [...prevSelectedFinalIds, wholeId]
    );
  };

  const handleCheckboxChangefinal = (finalId) => {
    setSelectedFinalIds((prevSelectedFinalIds) =>
      prevSelectedFinalIds.includes(finalId)
        ? prevSelectedFinalIds.filter((id) => id !== finalId)
        : [...prevSelectedFinalIds, finalId]
    );
  };

  useEffect(() => {
    if (Array.isArray(termData)) {
      const coverage = termData.map((item) => item.termCoverage);
      setTermCoverage(coverage);
    } else {
      setTermCoverage([]);
    }
  }, [termData]);

  useEffect(() => {
    if (Array.isArray(wholeData)) {
      const coverage = wholeData.map((item) => item.wholeLifeCoverage);
      // console.log('Coverage:', coverage);
      setWholeCoverage(coverage);
    } else {
      setWholeCoverage([]);
    }
  }, [wholeData]);

  useEffect(() => {
    if (Array.isArray(finalData)) {
      const coverage = finalData.map((item) => item.finalExpenseCoverage);
      // console.log('Coverage:', coverage);
      setFinalCoverage(coverage);
    } else {
      setFinalCoverage([]);
    }
  }, [finalData]);

  useEffect(() => {
    const fetchDebtData = async () => {
      try {
        const response = await api.get(`/debt/getAllDebtDetails/${userId}`);
        setDebtData(response.data.debtData);
      } catch (error) {
        console.error("Error fetching debt data:", error);
      }
    };

    if (!debtData) {
      fetchDebtData();
    }
  }, [debtData]);

  useEffect(() => {
    const fetchTermData = async () => {
      try {
        const response = await api.get(`/coverage/getAllTermCoverageDetails/${userId}`);
        setTermData(response.data.coverageData);
      } catch (error) {
        console.error("Error fetching term coverage data:", error);
      }
    };

    if (!termData) {
      fetchTermData();
    }
  }, [termData]);
  
  useEffect(() => {
    const fetchWholeData = async () => {
      try {
        const response = await api.get(`/coverage/getAllWholeLifeCoverageDetails/${userId}`);
        setWholeData(response.data.coverageData);
      } catch (error) {
        console.error("Error fetching term coverage data:", error);
      }
    };

    if (!wholeData) {
      fetchWholeData();
    }
  }, [wholeData]);

  useEffect(() => {
    const fetchFinalData = async () => {
      try {
        const response = await api.get(`/coverage/getAllFinalExpenseCoverageDetails/${userId}`);
        setFinalData(response.data.coverageData);
      } catch (error) {
        console.error("Error fetching final coverage data:", error);
      }
    };

    if (!finalData) {
      fetchFinalData();
    }
  }, [finalData]);

  useEffect(() => {
    const storedClient = localStorage.getItem("client");
    if (storedClient) {
      setLocalClient(JSON.parse(storedClient));
    }
  }, []);

  useEffect(() => {
    if (client) {
      localStorage.setItem("client", JSON.stringify(client));
      setLocalClient(client);
    }
  }, [client]);

  const handleToggle = () => {
    setIsShown(!isShown);
  };

  const handleToggleDebate = () => {
    setIsShownDebate(!isShownDebate);
  };

  const handleReviewClick = () => {
    navigate(`/details/:id/reviews`);
  };

  const handleRoute = () => {
    navigate("/dashboard");
  };

  const handleCreate = () => {
    navigate("/create");
  };

  const handleDebtRoute = (debtId) => {
    console.log("Navigating to debt details with ID:", debtId);
    navigate(`/details/${localClient.id}/debt/${userId}/${debtId}`);
  };

  const handleFinalRoute = (finalId) => {
    console.log("Navigating to debt details with ID:", finalId);
    navigate(`/details/${localClient.id}/coverage/${userId}/${finalId}`);
  };

  const handleWholeRoute = (wholeId) => {
    console.log("Navigating to debt details with ID:", wholeId);
    navigate(`/details/${localClient.id}/coverage/${userId}/${wholeId}`);
  };

  const handleTermRoute = (termId) => {
    console.log("Navigating to debt details with ID:", termId);
    navigate(`/details/${localClient.id}/coverage/${userId}/${termId}`);
  };

  const handleAddClick = () => {
    navigate(`/details/${localClient.id}/debt`);
  };

  const handleAddClickCoverage = () => {
    navigate(`/details/${localClient.id}/coverage`);
  };

  const handleCheckboxChange = (loanId) => {
    setCheckedDebts((prevCheckedDebts) =>
      prevCheckedDebts.includes(loanId)
        ? prevCheckedDebts.filter((id) => id !== loanId)
        : [...prevCheckedDebts, loanId]
    );
  };

  const deleteDebt = async () => {
    try {
      await Promise.all(
        checkedDebts.map(async (debtId) => {
          console.log(checkedDebts,"checked");
          await api.delete(`/debt/deleteDebt/${debtId}`);
        })
      );
      const response = await api.get(`/debt/getAllDebtDetails/${userId}`);
      setDebtData(response.data.debtData);
      setCheckedDebts([]);
    } catch (error) {
      console.error("Error deleting debt:", error);
    }
  };

  const deleteTerm = async () => {
    try {
      await Promise.all(
        selectedFinalIds.map(async (termId) => {
          console.log(selectedFinalIds,"selected");
          await api.delete(`/coverage/deleteTermCoverage/${termId}`);
        })
      );
  
      const response = await api.get(`/coverage/getAllTermCoverageDetails/${userId}`);
      console.log(response,"qwwdscd");
      setTermData(response.data.termData);
      setSelectedFinalIds([]);
    } catch (error) {
      console.error("Error deleting final expense coverage:", error);
    }
  };
  
  const deleteWhole = async () => {
    try {
      await Promise.all(
        selectedFinalIds.map(async (finalId) => {
          console.log(selectedFinalIds,"selected");
          await api.delete(`/coverage/deleteWholeLifeCoverage/${finalId}`);
        })
      );
  
      const response = await api.get(`/coverage/getAllWholeLifeCoverageDetails/${userId}`);
      setWholeData(response.data.wholeData);
      setSelectedFinalIds([]);
    } catch (error) {
      console.error("Error deleting final expense coverage:", error);
    }
  };

  const deleteFinal = async () => {
    try {
      await Promise.all(
        selectedFinalIds.map(async (finalId) => {
          console.log(selectedFinalIds,"selected");
          await api.delete(`/coverage/deleteFinalExpenseCoverage/${finalId}`);
        })
      );
  
      const response = await api.get(`/coverage/getAllFinalExpenseCoverageDetails/${userId}`);
      setFinalData(response.data.finalData);
      setSelectedFinalIds([]);
    } catch (error) {
      console.error("Error deleting final expense coverage:", error);
    }
  };

  return (
    <Drawer
      variant="permanent"
      classes={{ paper: "drawer-paper" }}
      sx={{
        "& .MuiDrawer-paper": {
          position: "relative",
          height: "100vh",
          background: "#DDDDDD",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box className="left-side">
          <Box className="logo-section">
            <img
              src="https://zibtek.nyc3.cdn.digitaloceanspaces.com/Mazzzing/insurality.png"
              alt="Logo"
              style={{ height: 40 }}
            />
          </Box>
          <Box sx={{ margin: '0 45px',height:'35px' }}> {/* Adjust width as needed */}
              <TextField
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: '#B0B0B0' }} /> {/* Light gray search icon */}
                    </InputAdornment>
                  ),
                  sx: {
                    backgroundColor: '#FFF', // White background for search bar
                    borderRadius: 1,
                    height: '35px'
                  }
                }}
              />
            </Box>
          <List sx={{padding:'20px'}}>
            <div className="row">
              <div className="leftSection">
                <span className="icon" onClick={handleRoute}>
                  ◀
                </span>
                <span className="text">{localClient?.personal?.firstName}</span>
              </div>
              <span className="circles plus" onClick={handleCreate}></span>
            </div>

            <div className="top">
              <div className="row" onClick={handleToggle}>
                <div className="leftSection">
                  <span className="icon">{isShown ? "▼" : "▶"}</span>
                  <span className="text">COVERAGE</span>
                </div>
                <span onClick={handleAddClickCoverage}>
                  <IconButton>
                    <AddIcon className="plus" />
                  </IconButton>
                </span>
              </div>

              {isShown && (
                <>

                  <h4 className="textAlign">Term</h4>

                  <List component="div" disablePadding>
                    {termData && termData.length > 0 ? (
                      termData.map((coverage, index) => {
                        const termId = coverage._id;

                        return (
                          <ListItem
                            key={index}
                            sx={{ pl: 2 }}
                            onClick={() => handleTermRoute(termId)}
                            // onClick={() => handleDebtRoute(termId)}
                            // onClick={() => navigate(`/details/${localClient.id}/coverage/${userId}`)}
                            button // Makes the entire ListItem clickable
                            >
                            <Checkbox
                              checked={selectedFinalIds.includes(termId)}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent routing when clicking the checkbox
                              }}
                              onChange={() => handleCheckboxChangeterm(termId)}
                            />
                            <div className="new-row">
                              <div className="text-cov">
                                <span className="key-style">
                                  {coverage.termCoverage.carrier}
                                </span>
                              </div>
                              <div className="iconsize">
                                <span style={{color:'gray'}}>
                                  {coverage.termCoverage.deathBenefit}
                                </span>
                              </div>
                            </div>
                            <ListItemText />
                          </ListItem>
                        );
                      })
                    ) : (
                      <ListItem>
                        <Typography variant="body1" sx={{ pl: 2 }}>
                          No Terms found.
                        </Typography>
                      </ListItem>
                    )}

                    <ListItem
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        height: "20",
                      }}
                      >
                      <Button
                        variant="contained"
                        color="error"
                        sx={{
                          height: 25,
                          padding: "3px 5px",
                          backgroundColor: "#0A406D",
                          fontSize: "10px",
                          color: "#C7C8CA",
                        }}
                        onClick={deleteTerm}
                      >
                        DELETE
                      </Button>
                    </ListItem>
                  </List>
                  <h4 className="textAlign">Whole Life</h4>

                  <List component="div" disablePadding>
                    {wholeData && wholeData.length > 0 ? (
                      wholeData.map((coverage, index) => {
                        const wholeId = coverage._id;

                        return (
                          <ListItem
                            key={index}
                            sx={{ pl: 2 }}
                            onClick={() => handleWholeRoute(wholeId)}
                            button
                          >
                            <Checkbox
                              checked={selectedFinalIds.includes(wholeId)}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent routing when clicking the checkbox
                              }}
                              onChange={() => handleCheckboxChangewhole(wholeId)}
                            />
                            <div className="new-row">
                              <div className="text-cov">
                                <span className="key-style">
                                  {coverage.wholeLifeCoverage.carrier}
                                </span>
                              </div>
                              <div className="iconsize">
                                <span style={{color:'gray'}}>
                                  {coverage.wholeLifeCoverage.year1}
                                </span>
                              </div>
                            </div>
                            <ListItemText />
                          </ListItem>
                        );
                      })
                    ) : (
                      <ListItem>
                        <Typography variant="body1" sx={{ pl: 2 }}>
                          No debts found.
                        </Typography>
                      </ListItem>
                    )}

                    <ListItem
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        height: "20",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="error"
                        sx={{
                          height: 25,
                          padding: "3px 5px",
                          backgroundColor: "#0A406D",
                          fontSize: "10px",
                          color: "#C7C8CA",
                        }}
                        onClick={deleteWhole}
                      >
                        DELETE
                      </Button>
                    </ListItem>
                  </List>

                  <h4 className="textAlign">Final Expense</h4>

                  <List component="div" disablePadding>
                    {finalData && finalData.length > 0 ? (
                      finalData.map((coverage, index) => {
                        const finalId = coverage._id;

                        return (
                          <ListItem key={index} onClick={() => handleFinalRoute(finalId)} sx={{ pl: 2 }} button>
                            <Checkbox
                              checked={selectedFinalIds.includes(finalId)}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent routing when clicking the checkbox
                              }}
                              onChange={() => handleCheckboxChangefinal(finalId)}
                            />
                            <div className="new-row">
                              <div className="text-cov">
                                <span className="key-style">
                                  {coverage.finalExpenseCoverage.carrier}
                                </span>
                              </div>
                              <div className="iconsize">
                                <span style={{color:'gray'}}>
                                  {coverage.finalExpenseCoverage.deathBenefit}
                                </span>
                              </div>
                            </div>
                            <ListItemText />
                          </ListItem>
                        );
                      })
                    ) : (
                      <ListItem>
                        <Typography variant="body1" sx={{ pl: 2 }}>
                          No coverage data found.
                        </Typography>
                      </ListItem>
                    )}

                    <ListItem
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        height: "20",
                      }}
                      >
                      <Button
                        variant="contained"
                        color="error"
                        sx={{
                          height: 25,
                          padding: "3px 5px",
                          backgroundColor: "#0A406D",
                          fontSize: "10px",
                          color: "#C7C8CA",
                        }}
                        onClick={deleteFinal}
                      >
                        DELETE
                      </Button>
                    </ListItem>
                  </List>
                </>
              )}
            </div>
            <Divider sx={{borderColor:'#fff'}} />
            <div>
              <div className="row" onClick={handleToggleDebate}>
                <div className="leftSection">
                  <span className="icon">{isShownDebate ? "▼" : "▶"}</span>
                  <span className="text">DEBT</span>
                </div>
                <span onClick={handleAddClick}>
                  <IconButton>
                    <AddIcon className="plus" />
                  </IconButton>
                </span>
              </div>
              {isShownDebate && (
                <List component="div" disablePadding>
                  {debtData && debtData.length > 0 ? (
                    debtData.map((loan, index) => {
                      const loanId = loan._id;

                      return (
                        <ListItem
                          key={index}
                          sx={{ pl: 2 }}
                          onClick={() => handleDebtRoute(loanId)} // Pass the loanId to handleDebtRoute
                          button // Makes the entire ListItem clickable
                        >
                          <Checkbox
                            checked={checkedDebts.includes(loanId)}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent routing when clicking the checkbox
                            }}
                            onChange={() => handleCheckboxChange(loanId)}
                          />
                          <div className="new-row">
                            <div className="text-cov">
                              <span className="key-style">{loan.type}</span>
                            </div>
                            <div className="iconsize">
                              <span style={{color:"gray"}}>{loan.loanAmount}</span>
                            </div>
                          </div>
                          <ListItemText />
                        </ListItem>
                      );
                    })
                  ) : (
                    <ListItem>
                      <Typography variant="body1" sx={{ pl: 2 }}>
                        No Final Expense found.
                      </Typography>
                    </ListItem>
                  )}
                  <ListItem
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      height: "20",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      sx={{
                        height: 25,
                        padding: "3px 5px",
                        backgroundColor: "#0A406D",
                        fontSize: "10px",
                        color: "#C7C8CA",
                      }}
                      onClick={deleteDebt}
                    >
                      DELETE
                    </Button>
                  </ListItem>
                </List>
              )}
            </div>
          </List>
        </Box>

        <Box className="bottom-section">
          <Typography variant="body1" sx={{letterSpacing: 2,fontWeight:'bold'}} onClick={handleReviewClick}>
            REVIEWS
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};

export default LeftSidenav;
