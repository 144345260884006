import React, { createContext, useContext, useEffect, useState } from 'react';
import api from '../api/tokenCode';

const WholeDataContext = createContext();

export const WholeDataProvider = ({ children }) => {
  const [wholeData, setWholeData] = useState(null);
  const clientData = JSON.parse(localStorage.getItem('client'));
  const userId = clientData?.user_id;

  useEffect(() => {
    const fetchWholeData = async () => {
      try {
        const response = await api.get(`/coverage/getAllWholeLifeCoverageDetails/${userId}`);
        setWholeData(response.data.coverageData);
      } catch (error) {
        console.error("Error fetching term coverage data:", error);
      }
    };

    if (userId) {
      fetchWholeData();
    }
  }, [userId]);

  return (
    <WholeDataContext.Provider value={wholeData}>
      {children}
    </WholeDataContext.Provider>
  );
};

export const useWholeData = () => useContext(WholeDataContext);
