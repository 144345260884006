import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ClientProvider } from './context/ClientContext';
import { TermDataProvider } from './context/TermDataContext';
import { WholeDataProvider } from './context/WholeDataContext';
import { FinalDataProvider } from './context/FinalDataContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ClientProvider>
      <FinalDataProvider>
      <WholeDataProvider>
        <TermDataProvider>
          <App />
        </TermDataProvider>
      </WholeDataProvider>
      </FinalDataProvider>
    </ClientProvider>
  </React.StrictMode>
);

reportWebVitals();
