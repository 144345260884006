import React, { useState, useEffect } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import Header from "../HeadingList/HeadingList"; // Adjust the path as needed
import "./FormDesign.css";

const ClientInfo = ({ onDataChange, initialData }) => {
  const [formData, setFormData] = useState({
    personal: {
      firstName: "",
      lastName: "",
      birthDay: "",
    },
    spouse: {
      firstName: "",
      lastName: "",
      birthDay: "",
    },
    location: {
      address: "",
      city: "",
      state: "",
      zip: "",
    },
  });

  // Update formData when initialData changes
  useEffect(() => {
    if (initialData) {
      // console.log(initialData,"iugufcghv");

      setFormData({
        personal: {
          firstName: initialData.personal?.firstName || "",
          lastName: initialData.personal?.lastName || "",
          birthDay: initialData.personal?.birthDay || "",
        },
        spouse: {
          firstName: initialData.spouse?.firstName || "",
          lastName: initialData.spouse?.lastName || "",
          birthDay: initialData.spouse?.birthDay || "",
        },
        location: {
          address: initialData.location?.address || "",
          city: initialData.location?.city || "",
          state: initialData.location?.state || "",
          zip: initialData.location?.zip || "",
        },
      });
    }
  }, [initialData]);

  // Call onDataChange when formData changes
  useEffect(() => {
    onDataChange(formData);
  }, [formData, onDataChange]);

  // Handle changes in input fields
  const handleChange = (section, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };

  return (
    <>
      <Header name="CLIENT INFO" className="headerSpace" />
      <Box className="mt-1" sx={{ marginBottom: 6 ,padding: 2}}>

      {/* Personal Information */}
        <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1 ,flexWrap:'nowrap'}}>
          <Grid xs={12} sm={2}>
            <Box sx={{ display: "flex", alignItems: "center" }} className="newClass">
              <Typography variant="body1" className="list-1">
                Personal
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
            <div className="grid-item">
              <input
                type="text"
                placeholder="First Name"
                value={formData.personal.firstName}
                onChange={(e) =>
                  handleChange("personal", "firstName", e.target.value)
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Last Name"
                value={formData.personal.lastName}
                onChange={(e) =>
                  handleChange("personal", "lastName", e.target.value)
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="date"
                placeholder="hchgc"
                value={formData.personal.birthDay}
                onChange={(e) =>
                  handleChange("personal", "birthDay", e.target.value)
                }
                style={{
                  width: "100%",
                  padding: "8px",
                  boxSizing: "border-box",
                }}
              />
            </div>
          </div>
        </Grid>

      {/* Spouse Information */}
      <Grid container spacing={2} className="gridHeight"  alignItems="center" sx={{ mt: 1,flexWrap:'nowrap' }}>
        <Grid xs={12} sm={2}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" className="list-1">
              Spouse
            </Typography>
          </Box>
        </Grid>
        <div className="grid-container">
          <div className="grid-item">
            <input
              type="text"
              placeholder="First Name"
              value={formData.spouse.firstName}
              onChange={(e) =>
                handleChange("spouse", "firstName", e.target.value)
              }
            />
          </div>

          <div className="grid-item">
            <input
              type="text"
              placeholder="Last Name"
              value={formData.spouse.lastName}
              onChange={(e) =>
                handleChange("spouse", "lastName", e.target.value)
              }
            />
          </div>

          <div className="grid-item">
            <input
              type="date"
              value={formData.spouse.birthDay}
              onChange={(e) =>
                handleChange("spouse", "birthDay", e.target.value)
              }
              style={{
                width: "100%",
                padding: "8px",
                boxSizing: "border-box",
              }}
            />
          </div>
        </div>
      </Grid>

      {/* Location Information */}
      <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1,flexWrap:'nowrap' }}>
        <Grid xs={12} sm={2}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" className="list-1 locResp">
              Location
            </Typography>
          </Box>
        </Grid>

        <div className="grid-container">
          <div className="grid-item address-input">
            <input
              type="text"
              className="address-input"
              placeholder="Address"
              value={formData.location.address}
              onChange={(e) =>
                handleChange("location", "address", e.target.value)
              }
            />
          </div>

          <div className="grid-item">
            <input
              type="text"
              placeholder="City"
              value={formData.location.city}
              onChange={(e) =>
                handleChange("location", "city", e.target.value)
              }
            />
          </div>

          <div className="grid-item">
            <input
              type="text"
              placeholder="State"
              value={formData.location.state}
              onChange={(e) =>
                handleChange("location", "state", e.target.value)
              }
            />
          </div>

          <div className="grid-item">
            <input
              type="text"
              placeholder="Zip"
              value={formData.location.zip}
              onChange={(e) =>
                handleChange("location", "zip", e.target.value)
              }
            />
          </div>
        </div>
        
      </Grid>
    </Box>
    </>

  );
};

export default ClientInfo;
