import React from 'react';
import LeftSidenav from './LeftSidenav/LeftSidenav';
import { Box,Typography } from '@mui/material';
import RightNav from './RightNav/RightNav';
import  SurplusChart  from "./Report/Graph";

const ClientDetails = () => {

  return (
    <Box sx={{ display: 'flex',height:'100vh' }}>
      <LeftSidenav />
      <Box component="main" sx={{ flexGrow: 1, p: 3,overflowY:'auto' }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{textAlign:'center',color:'#24527B',letterSpacing:'5px',fontFamily:'fangsong'}}>
          Life Time Report
        </Typography>
        <SurplusChart />
      </Box>
      <RightNav />
    </Box>
  );
};

export default ClientDetails;
