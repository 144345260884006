import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import LeftSidenav from '../LeftSidenav/LeftSidenav';
import './Coverage.css'; 
import api from "../../api/tokenCode";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { notifySuccess, notifyError } from '../../constant/toast';

const InputField = ({ name, placeholder, className, field, error }) => (
    <div className="input-field-container">
        <input
            type="text"
            className={className}
            placeholder={placeholder}
            {...field}
        />
        {error && <p className="error-message">{error.message}</p>}
    </div>
);

const FormSection = ({ form, onSubmit, fields, title }) => (
    <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="container">
            <p className="text-with-underline">{title}</p>
        </div>
    <hr className='hr-tag' />
        <div className="input-container">
            {fields.map(({ name, placeholder, className, rules }) => (
                <Controller
                    key={name}
                    name={name}
                    control={form.control}
                    rules={rules}
                    render={({ field }) => (
                        <InputField
                            name={name}
                            placeholder={placeholder}
                            className={className}
                            field={field}
                            error={form.formState.errors[name]}
                        />
                    )}
                />
            ))}
            <div>
                <button type="submit" className="submit-button">Update</button>
            </div>
        </div>
    </form>
);

const UpdateCoverage = () => {
    const { id } = useParams();
    const termForm = useForm();
    const wholeLifeForm = useForm();
    const finalExpenseForm = useForm();
    const [termData, setTermData] = useState(null);
    const [wholeData, setWholeData] = useState(null);
    const [finalData, setFinalData] = useState(null);
    const clientData = JSON.parse(localStorage.getItem('client'));
    const clientId = clientData.user_id;
    const [savedCoverage, setSavedCoverage] = useState(null);
    const [wholeDetails, setWholeDetails] = useState([]);
    const [finalDetails, setFinalDetails] = useState([]);
    const [termDetails, setTermDetails] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTermData = async () => {
            try {
                const response = await api.get(`coverage/getTermCoverageDetails/${clientId}/${id}`);
                const coverageType = response.data.coverageData[0].coverageType;
                console.log("Coverage Type:", coverageType);

                if (coverageType === 'term') {
                    const coverageData = response.data.coverageData[0].termCoverage;
                    setTermData(coverageType); // Set the coverageType in state

                    // Populate form fields with fetched data
                    termForm.setValue('termCarrier', coverageData.carrier || '');
                    termForm.setValue('termDeathBenefit', coverageData.deathBenefit || '');
                    termForm.setValue('termStartDate', coverageData.startDate || '');
                    termForm.setValue('termLengthYears', coverageData.length || '');
                }

            } catch (error) {
                console.error("Error fetching coverage data:", error);
            }
        };  
        fetchTermData();
    }, [id]);

    useEffect(() => {
        const fetchWholeData = async () => {
            try {
                const response = await api.get(`coverage/getWholeLifeCoverageDetails/${clientId}/${id}`);
                const coverageType = response.data.coverageData[0].coverageType;
                console.log("Coverage Type:", coverageType);

                if (coverageType === 'wholeLifeCoverage') {
                    const coverageData = response.data.coverageData[0].wholeLifeCoverage;
                    setWholeData(coverageType);

                    // // Populate form fields with fetched data
                wholeLifeForm.setValue('wholeLifeCarrier', coverageData.carrier || '');
                wholeLifeForm.setValue('wholeLifeYear1', coverageData.year1 || '');
                wholeLifeForm.setValue('wholeLifeYear5', coverageData.year5 || '');
                wholeLifeForm.setValue('wholeLifeYear10', coverageData.year10 || '');
                wholeLifeForm.setValue('wholeLifeYear20', coverageData.year20 || '');
                wholeLifeForm.setValue('wholeLifeYear100', coverageData.year100 || '');
                wholeLifeForm.setValue('wholeLifeStartDate', coverageData.startDate || '');

                }

                

            } catch (error) {
                console.error("Error fetching coverage data:", error);
            }
        };  
        fetchWholeData();
    }, [id]);

    useEffect(() => {
        const fetchCoverageData = async () => {
            try {
                const response = await api.get(`coverage/getFinalExpenseCoverageDetails/${clientId}/${id}`);
                const coverageType = response.data.coverageData[0].coverageType;
                console.log("Coverage Type:", coverageType);

                if (coverageType === 'finalExpenseCoverage') {
                    const coverageData = response.data.coverageData[0].finalExpenseCoverage;
                    setFinalData(coverageType);

                    // Populate form fields with fetched data
                    finalExpenseForm.setValue('finalExpenseCarrier', coverageData.carrier || '');
                    finalExpenseForm.setValue('finalExpenseDeathBenefit', coverageData.deathBenefit || '');
                    finalExpenseForm.setValue('finalExpenseStartDate', coverageData.startDate || '');
                }
                
            } catch (error) {
                console.error("Error fetching coverage data:", error);
            }
        };  
        fetchCoverageData();
    }, [id]);
     

    const handleSubmitTermCoverage = async (data) => {
        const processedData = {
        
                
                    termCoverage: {
                        carrier: data.termCarrier || '',
                        deathBenefit: data.termDeathBenefit || '',
                        startDate: data.termStartDate || '',
                        length: data.termLengthYears || ''
                    },
                    clientId,
                    coverageType:'term'
                
            
        };

        const updatedLoanDetails =[...termDetails, processedData];
        setTermDetails(updatedLoanDetails);

        try {
            const response = await api.put(`/coverage/updateTermCoverage/${id}`,{
                processedData : updatedLoanDetails
            });
            setFinalData(response.data.wholeLifeCarrier); // Optionally update the state with the new data
            wholeLifeForm.reset(response.data.wholeLifeCarrier);
            notifySuccess('Term Created successfully!');
            setTimeout(() => {
            navigate(`/details/${clientData.id}`);
            }, 3000);

        } catch (error) {
            console.error('Form submission error:', error);
        }
    };

    const handleSubmitWholeLifeCoverage = async (data) => {
        const processedData = {
                
                    wholeLifeCoverage: {
                        carrier: data.wholeLifeCarrier || '',
                        year1: data.wholeLifeYear1 || '',
                        year5: data.wholeLifeYear5 || '',
                        year10: data.wholeLifeYear10 || '',
                        year20: data.wholeLifeYear20 || '',
                        year100: data.wholeLifeYear100 || '',
                        startDate: data.wholeLifeStartDate || ''
                    },
                    clientId,
                    coverageType:'wholeLifeCoverage'
        };

        const updatedLoanDetails = [...wholeDetails, processedData];
        setWholeDetails(updatedLoanDetails);

        try {
            const response = await api.put(`/coverage/updateWholeLifeCoverage/${id}`, {
                processedData : updatedLoanDetails
            });

            setFinalData(response.data.wholeLifeCoverage); // Optionally update the state with the new data
            wholeLifeForm.reset(response.data.wholeLifeCoverage);
            notifySuccess('Whole Life Created successfully!');
            setTimeout(() => {
            navigate(`/details/${clientData.id}`);
            }, 3000);

        } catch (error) {
      notifyError('Error submitting the form. Please try again.');
            console.error('Form submission error:', error);
        }
    };

    const handleSubmitFinalExpenseCoverage = async (data) => {
        const processedData = {
            finalExpenseCoverage: {
                carrier: data.finalExpenseCarrier || '',
                deathBenefit: data.finalExpenseDeathBenefit || '',
                startDate: data.finalExpenseStartDate || ''
            },
            clientId,
            coverageType: 'final'
        };

        const updatedLoanDetails = [...finalDetails, processedData];
        setFinalDetails(updatedLoanDetails);

        try {
            // API call to update the final expense coverage
            const response = await api.put(`/coverage/updateFinalExpenseCoverage/${id}`, {
                processedData: updatedLoanDetails,
            });
            setFinalData(response.data.finalExpenseCoverage); // Optionally update the state with the new data
            finalExpenseForm.reset(response.data.finalExpenseCoverage); // Reset form with updated data
            notifySuccess('Final Expenses Updated successfully!');
            setTimeout(() => {
                navigate(`/details/${clientData.id}`);
            }, 3000);
        } catch (error) {
            console.error('Form submission error:', error);
        }
    };

    const handleRowClick = () => {
        navigate(`/details/${clientData.id}`);
      };

    const termFields = [
        { name: 'termCarrier', placeholder: 'Carrier', className: 'input-field-sub-1', rules: { required: '* Carrier is required' } },
        { name: 'termDeathBenefit', placeholder: 'Death Benefit', className: 'input-field-sub-2', rules: { required: '* Death Benefit is required' } },
        { name: 'termStartDate', placeholder: 'Start Date', className: 'input-field-sub-2', rules: { required: '* Start Date is required' } },
        { name: 'termLengthYears', placeholder: 'Length (years)', className: 'input-field-sub-2', rules: { required: '* Length (years) is required' } },
    ];

    const wholeLifeFields = [
        { name: 'wholeLifeCarrier', placeholder: 'Carrier', className: 'input-field-1', rules: { required: '* Carrier is required' } },
        { name: 'wholeLifeYear1', placeholder: 'Year 1', className: 'input-field-5', rules: { required: '* Year 1 is required' } },
        { name: 'wholeLifeYear5', placeholder: 'Year 5', className: 'input-field-5', rules: { required: '* Year 5 is required' } },
        { name: 'wholeLifeYear10', placeholder: 'Year 10', className: 'input-field-5', rules: { required: '* Year 10 is required' } },
        { name: 'wholeLifeYear20', placeholder: 'Year 20', className: 'input-field-5', rules: { required: '* Year 20 is required' } },
        { name: 'wholeLifeYear100', placeholder: 'Year 100', className: 'input-field-5', rules: { required: '* Year 100 is required' } },
        { name: 'wholeLifeStartDate', placeholder: 'Start Date', className: 'input-field-1', rules: { required: '* Start Date is required' } },
    ];

    const finalExpenseFields = [
        { name: 'finalExpenseCarrier', placeholder: 'Carrier', className: 'input-field-1', rules: { required: '* Carrier is required' } },
        { name: 'finalExpenseDeathBenefit', placeholder: 'Death Benefit', className: 'input-field-2', rules: { required: '* Death Benefit is required' } },
        { name: 'finalExpenseStartDate', placeholder: 'Start Date', className: 'input-field-2', rules: { required: '* Start Date is required' } },
    ];

    return (
        <Box sx={{ display: 'flex',height:'100vh' }}>
            <LeftSidenav />
            <Box component="main" className='coveragePadding' sx={{ flexGrow: 1, overflowY:'auto',bgcolor: 'background.default', paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10,overflowY: 'auto' }}>
                <div className="main-container">
                    <div>
                    <h1 className="debt-name">UPDATE COVERAGE</h1>
                   <button type="submit"className="back-button"  onClick={handleRowClick}>BACK</button>
                   </div>

                </div>
                <div>
                    {termData === 'term' && (
                        <FormSection
                            form={termForm}
                            onSubmit={handleSubmitTermCoverage}
                            fields={termFields}
                            title="TERM"
                        />
                    )}

                    {wholeData === 'wholeLifeCoverage' && (
                        <FormSection
                            form={wholeLifeForm}
                            onSubmit={ handleSubmitWholeLifeCoverage}
                            fields={wholeLifeFields}
                            title="WHOLE LIFE"
                        />
                    )}

                    {finalData === 'finalExpenseCoverage' && (
                        <FormSection
                            form={finalExpenseForm}
                            onSubmit={handleSubmitFinalExpenseCoverage}
                            fields={finalExpenseFields}
                            title="FINAL EXPENSE"
                        />
                    )}
                </div>
            </Box>
        <ToastContainer />
        </Box>
    );
};

export default UpdateCoverage;
