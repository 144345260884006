import React, { createContext, useContext, useEffect, useState } from 'react';
import api from '../api/tokenCode';

const TermDataContext = createContext();

export const TermDataProvider = ({ children }) => {
  const [termData, setTermData] = useState(null);
  const clientData = JSON.parse(localStorage.getItem('client'));
  const userId = clientData?.user_id;

  useEffect(() => {
    const fetchTermData = async () => {
      try {
        const response = await api.get(`/coverage/getAllTermCoverageDetails/${userId}`);
        setTermData(response.data.coverageData);
      } catch (error) {
        console.error("Error fetching term coverage data:", error);
      }
    };

    if (userId) {
      fetchTermData();
    }
  }, [userId]);

  return (
    <TermDataContext.Provider value={termData}>
      {children}
    </TermDataContext.Provider>
  );
};

export const useTermData = () => useContext(TermDataContext);
