import React, { useState, useEffect, useContext } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Checkbox,
  TablePagination,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Box,
  Button,
  Stack,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import './Dashboard.css';
import ClientContext from '../../context/ClientContext';
import api from '../../api/tokenCode';

const ReactTable = () => {
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState({
    firstName: '',
    lastName: '',
    birthday: null,
  });
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const { setClient } = useContext(ClientContext);
  const navigate = useNavigate();

  const fetchData = async (page, rowsPerPage) => {
    try {
      const response = await api.get(`/form/getAllClientDetails`, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
        },
      });
      setRows(response.data.data);
    } catch (error) {
      console.error('Error fetching client details:', error);
    }
  };

  useEffect(() => {
    fetchData(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/form/getAllClientDetails');
        setRows(response.data.data);
      } catch (error) {
        console.error('Error fetching client details:', error);
      }
    };

    fetchData();
  }, []);

  const handleDeleteRow = (id) => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
    setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
  };

  const handleSelectRow = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleRowClick = (row) => {
    setClient(row);
    localStorage.setItem('client', JSON.stringify(row)); // Store client details in localStorage
    navigate(`/details/${row.id}`);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleBirthdayChange = (date) => {
    setFilters({ ...filters, birthday: date });
  };
  

  const handleFilterTypeChange = (event) => {
    const selectedType = event.target.value;
    if (!selectedFilters.includes(selectedType)) {
      setSelectedFilters([...selectedFilters, selectedType]);
    }
  };

  const handleApplyFilter = () => {
    setShowFilters(false);
  };

  const handleClearFilters = () => {
    setFilters({
      firstName: '',
      lastName: '',
      birthday: null,
    });
    setSelectedFilters([]);
    setShowFilters(false);
  };

  // const parseDate = (dateString) => {
  //   const [year, month, day] = dateString.split('-');
  //   return new Date(year, month - 1, day); // JavaScript months are 0-based
  // };

  const parseDate = (dateString) => {
    if (typeof dateString !== 'string') {
      return null; // or handle the invalid date format appropriately
    }
  
    const [year, month, day] = dateString.split('-');
    const date = new Date(year, month - 1, day); // JavaScript months are 0-based
  
    return isNaN(date.getTime()) ? null : date; // Return null if the date is invalid
  };
  
  const formatDate = (dateString) => {
    const date = new Date(dateString); // Convert string to Date object
    const day = String(date.getDate()).padStart(2, '0'); // Add leading 0 for day
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading 0 for month (getMonth() is 0-indexed)
    const year = date.getFullYear(); // Get the full year
    return `${day}/${month}/${year}`; // Return formatted date
  };

  const filteredRows = rows.filter((row) => {
    const rowDate = row.personal?.birthDay ? parseDate(row.personal.birthDay) : null;
    const filterDate = filters.birthday ? parseDate(filters.birthday) : null;
  
    return (
      row.personal.firstName.toLowerCase().includes(filters.firstName.toLowerCase()) &&
      row.personal.lastName.toLowerCase().includes(filters.lastName.toLowerCase()) &&
      (!filterDate || (rowDate && filterDate && format(rowDate, 'dd/MM/yyyy') === format(filterDate, 'dd/MM/yyyy')))
    );
  });
  

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredRows.length - page * rowsPerPage);

  return (
    <Box sx={{ p: 2 }}>
        <div>
           <h1 className="debt-name">CLIENTS</h1>
         </div>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between" mb={2}>
        <Grid item>
        </Grid>
        <Grid item>
          <IconButton
            sx={{
              fontSize: '16px',
              color: '#0A406D',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: 'transparent',
                '&:active': {
                  backgroundColor: 'transparent',
                },
              },
            }}
            onClick={() => setShowFilters(!showFilters)}
            aria-label="filter list"
          >
            <FilterListIcon />
            Add Filter
          </IconButton>
          <IconButton
            sx={{
              fontSize: '16px',
              color: '#0A406D',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: 'transparent',
                '&:active': {
                  backgroundColor: 'transparent',
                },
              },
            }}
            onClick={() => navigate('/create')}
            aria-label="filter list"
          >
            <AddIcon />
            Create
          </IconButton>
        </Grid>
      </Grid>

      {showFilters && (
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
            <Grid item xs={2} sm={2} sx={{ padding: '10px' }}>
              <FormControl fullWidth>
                <Select
                  labelId="filter-type-label"
                  id="filter-type-select"
                  value=""
                  onChange={handleFilterTypeChange}
                  sx={{ textAlign: 'left', height: '28px', padding: '16px' }}
                >
                  <MenuItem value="firstName">First Name</MenuItem>
                  <MenuItem value="lastName">Last Name</MenuItem>
                  <MenuItem value="birthday">Birthday</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {selectedFilters.includes('firstName') && (
              <Grid item xs={8} sm={1.5} sx={{ textAlign: 'left' }}>
                <TextField
                  name="firstName"
                  label="First Name"
                  variant="outlined"
                  size="small"
                  value={filters.firstName}
                  onChange={handleFilterChange}
                  sx={{ width: '200px', textAlign: 'left' }}
                />
              </Grid>
            )}
            {selectedFilters.includes('lastName') && (
              <Grid item xs={12} sm={1.5} sx={{ textAlign: 'left', maxWidth: '15%' }}>
                <TextField
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                  size="small"
                  value={filters.lastName}
                  onChange={handleFilterChange}
                  sx={{ width: '200px' }}
                />
              </Grid>
            )}
            {selectedFilters.includes('birthday') && (
              <Grid item xs={12} sm={1.5} sx={{ textAlign: 'left' }}>

            <TextField
              id="birthday-picker"
              label="Birthday"
              type="date"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ width: '200px' }}
              value={filters.birthday ? format(new Date(filters.birthday), 'yyyy-MM-dd') : ''}
              onChange={(e) => handleBirthdayChange(e.target.value)}
            />

              </Grid>
            )}
            <Grid item xs={12} sm={3}>
              <Stack direction="row" spacing={1}>
                <Button variant="contained" onClick={handleApplyFilter}>
                  Apply
                </Button>
                <Button variant="outlined" onClick={handleClearFilters}>
                  Clear
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      )}

      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                {/* <Checkbox
                  sx={{
                    marginRight: 1,
                    boxShadow: '3px 3px 1px gray',
                    border: 'none',
                    borderRadius: 0,
                    width: 16,
                    height: 16,
                    color: 'gray',
                    backgroundColor: 'transparent',
                    '& .MuiSvgIcon-root': {
                      fill: 'gray',
                    },
                    }}
                  indeterminate={selectedRows.length > 0 && selectedRows.length < rows.length}
                  checked={rows.length > 0 && selectedRows.length === rows.length}
                  onChange={() => {
                    if (selectedRows.length === rows.length) {
                      setSelectedRows([]);
                    } else {
                      setSelectedRows(rows.map((row) => row.id));
                    }
                  }}
                /> */}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '16px', textTransform: 'uppercase' }}>
                First Name
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '16px', textTransform: 'uppercase' }}>
                Last Name
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '16px', textTransform: 'uppercase' }}>
                Birthdate
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '16px', textTransform: 'uppercase' }}>
                Spouse
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '16px', textTransform: 'uppercase' }}>
                Address
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : filteredRows
            ).map((row) => (
              <TableRow key={row.id} onClick={() => handleRowClick(row)} style={{ cursor: 'pointer' }}>
                <TableCell sx={{textAlign:'center'}} padding="checkbox" onClick={(e) => e.stopPropagation()}>
                  <Checkbox sx={{
                   marginRight: 1,
                   boxShadow: '3px 3px 1px gray',
                   border: 'none',
                   borderRadius: 0,
                   width: 16,
                   height: 16,
                   color: 'gray',
                   backgroundColor: 'transparent',
                   '& .MuiSvgIcon-root': {
                     fill: 'gray',
                   },
                 }} checked={selectedRows.includes(row.id)} onChange={() => handleSelectRow(row.id)} />
                </TableCell>
                <TableCell>{row.personal.firstName}</TableCell>
                <TableCell>{row.personal.lastName}</TableCell>
                <TableCell>{formatDate(row.personal.birthDay)}</TableCell>
                <TableCell>{row.spouse?.firstName}</TableCell>
                <TableCell>{row.location?.address}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteRow(row.id);
                    }}
                    disabled={!selectedRows.includes(row.id)}
                  >
                    {/* <DeleteIcon /> */}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={9} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10,20,30,60,100]}
        component="div"
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default ReactTable;


