
import React, { useState, useEffect } from 'react';
import { Box, Grid, TextField, Typography, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Header from '../HeadingList/HeadingList';
import DeleteIcon from '@mui/icons-material/Delete';
import './FormDesign.css';

const FuturePlanningForm = ({ onDataChange, initialData }) => {
  const [data, setData] = useState({
    areYouGoingToRetire: { age: '' },
    retirementContribution: [{ monthly: '', annual: '', retirementIncome: false }],
    kidsCollegeFunding: [{ monthly: '', annual: '', collegeFunding: false }],
  });

  useEffect(() => {
    if (initialData) {
      setData({
        areYouGoingToRetire: { age: initialData.areYouGoingToRetire.age || '' },
        retirementContribution: initialData.retirementContribution.map(contribution => ({
          monthly: contribution.monthly || '',
          annual: contribution.annual || '',
          retirementIncome: contribution.retirementIncome === 'Yes',
        })),
        kidsCollegeFunding: initialData.kidsCollegeFunding.map(funding => ({
          monthly: funding.monthly || '',
          annual: funding.annual || '',
          collegeFunding: funding.collegeFunding === 'Yes',
        })),
      });
    }
  }, [initialData]);

  useEffect(() => {
    const payload = {
      areYouGoingToRetire: data.areYouGoingToRetire,
      retirementContribution: data.retirementContribution.map(contribution => ({
        monthly: contribution.monthly ? parseFloat(contribution.monthly) : '',
        annual: contribution.annual ? parseFloat(contribution.annual) : '',
        retirementIncome: contribution.retirementIncome ? 'Yes' : 'No',
      })),
      kidsCollegeFunding: data.kidsCollegeFunding.map(funding => ({
        monthly: funding.monthly ? parseFloat(funding.monthly) : '',
        annual: funding.annual ? parseFloat(funding.annual) : '',
        collegeFunding: funding.collegeFunding ? 'Yes' : 'No',
      })),
    };

    onDataChange(payload);
  }, [data, onDataChange]);

  const handleInputChange = (e, index, category, name) => {
    const { value, type, checked } = e.target;
  
    setData((prevData) => ({
      ...prevData,
      [category]: Array.isArray(prevData[category])
        ? prevData[category].map((item, i) =>
            i === index
              ? { ...item, [name]: type === "checkbox" ? checked : value }
              : item
          )
        : {
            ...prevData[category],
            [name]: type === "checkbox" ? checked : value,
          }, // If it's an object, update it directly
    }));
  };
  

  const handleCheckboxChange = (e, index, category, name) => {
    const { checked } = e.target;
  
    setData((prevData) => ({
      ...prevData,
      [category]: prevData[category].map((item, i) =>
        i === index ? { ...item, [name]: checked } : item
      ),
    }));
  };


    const addNewEntry = (type) => {
    setData(prevData => ({
      ...prevData,
      [type]: [...prevData[type], { name: '', age: '', lifeInsurance: false }],
    }));
  };

  const deleteEntry = (index, type) => {
    setData(prevData => ({
      ...prevData,
      [type]: prevData[type].filter((_, i) => i !== index),
    }));
  };

  return (
    <form>
      <Header name="FUTURE / PLANNING" />
      <Box sx={{ padding: 2 }}>

        <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1,flexWrap:'nowrap' }}>
          <Grid  xs={12} sm={2} className="responsiveWidth">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{whiteSpace:'nowrap'}}  variant="body1" className="list-1">
                  Age You Plan To Retire?
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">

            <div className="grid-item">
              <div className="emptyInput"></div> {/* Adjust width as needed */}
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Age"
                value={data.areYouGoingToRetire.age}
                onChange={(e) =>
                  handleInputChange(e,0,"areYouGoingToRetire", "age")

                }
              />
            </div>
          </div>
        </Grid>        

      {data.retirementContribution.map((contribution, index) => (
        <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1,flexWrap:'nowrap' }} key={index}>
          <Grid  xs={12} sm={2} className="responsiveWidth">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton sx={{padding:'1px',color:'#24527B'}} onClick={() => addNewEntry('retirementContribution')}>
                <AddIcon className="icons" />
              </IconButton>
              <Typography variant="body1" className="spouseTypo">
              Retirement Contribution
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
          <div className="grid-item">
              <div className="emptyInput"></div> {/* Adjust width as needed */}
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Monthly"
                value={contribution.monthly}
                onChange={(e) =>
                  handleInputChange(e,index, 'retirementContribution', 'monthly')
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Annual"
                value={contribution.annual}
                onChange={(e) =>
                  handleInputChange(e,index, 'retirementContribution', 'annual')
                }
              />
            </div>

            {/* Add Checkbox */}
            <div className="grid-item checkboxItem">
              <FormControlLabel
                sx={{whiteSpace:'nowrap'}}
                control={
                  <Checkbox
                    checked={contribution.retirementIncome}
                    onChange={(e) =>
                      handleCheckboxChange(e,index, 'retirementContribution','retirementIncome' )
                    }
                    sx={{
                      marginRight: 2,
                      boxShadow: '3px 3px 1px gray',
                      border: 'none',
                      borderRadius: 0,
                      width: 16,
                      height: 16,
                      color: 'gray',
                      backgroundColor: 'transparent',
                      '& .MuiSvgIcon-root': {
                        fill: 'gray',
                      },
                    }}
                  />
                  
                }
                label="Retirement Income"
              />
              {data.retirementContribution.length > 1 && (
                <IconButton color="#EFEFEF" className="iconFix" onClick={() => deleteEntry(index, 'retirementContribution')}>
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          </div>
        </Grid>
      ))}

      {data.kidsCollegeFunding.map((funding, index) => (
        <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1 ,flexWrap:'nowrap'}} key={index}>
          <Grid  xs={12} sm={2} className="responsiveWidth">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton sx={{padding:'1px',color:'#24527B'}} onClick={() => addNewEntry('kidsCollegeFunding')}>
                <AddIcon className="icons" />
              </IconButton>
              <Typography variant="body1" className="spouseTypo">
                  Kids College Funding
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
          <div className="grid-item">
              <div className="emptyInput"></div> {/* Adjust width as needed */}
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Monthly"
                value={funding.monthly}
                onChange={(e) =>
                  handleInputChange(e,index, 'kidsCollegeFunding', 'monthly')
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Annual"
                value={funding.annual}
                onChange={(e) =>
                  handleInputChange(e,index, 'kidsCollegeFunding', 'annual')
                }
              />
            </div>

            {/* Add Checkbox */}
            <div className="grid-item checkboxItem">
              <FormControlLabel
                sx={{whiteSpace:'nowrap'}}
                control={
                  <Checkbox
                    checked={funding.collegeFunding}
                    onChange={(e) =>
                      handleCheckboxChange(e,index,'kidsCollegeFunding','collegeFunding',)
                    }
                    sx={{
                      marginRight: 2,
                      boxShadow: '3px 3px 1px gray',
                      border: 'none',
                      borderRadius: 0,
                      width: 16,
                      height: 16,
                      color: 'gray',
                      backgroundColor: 'transparent',
                      '& .MuiSvgIcon-root': {
                        fill: 'gray',
                      },
                    }}
                  />
                  
                }
                label="College Funding"
              />
              {data.kidsCollegeFunding.length > 1 && (
                  <IconButton color="#EFEFEF" className="iconFix" onClick={() => deleteEntry(index, 'kidsCollegeFunding')}>
                    <DeleteIcon />
                  </IconButton>
                )}
            </div>
          </div>
        </Grid>
      ))}
      </Box>
    </form>
  );
};

export default FuturePlanningForm;
