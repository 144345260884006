import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import LeftSidenav from '../LeftSidenav/LeftSidenav';
import './Coverage.css';
import api from '../../api/tokenCode';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { notifySuccess, notifyError } from '../../constant/toast';

// Reusable InputField Component
const InputField = ({ name, placeholder, className, field, error }) => (
  <div className="input-field-container">
    <input
      type="text"
      className={className}
      placeholder={placeholder}
      {...field}
    />
    {error && <p className="error-message">{error.message}</p>}
  </div>
);

// Reusable FormSection Component
const FormSection = ({ form, onSubmit, fields, title }) => (
  <form onSubmit={form.handleSubmit(onSubmit)}>
    <div className="container">
      <p className="text-with-underline">{title}</p>
    </div>
    <hr className='hr-tag' />
    <div className='coverage-button-container'>
    <div className="input-containers">
      {fields.map(({ name, placeholder, className, rules, type }) => (
        <Controller
          key={name}
          name={name}
          control={form.control}
          rules={rules}
          render={({ field }) => (
            <InputField
              name={name}
              placeholder={placeholder}
              className={className}
              field={{ ...field, type }} // Pass type here
              error={form.formState.errors[name]}
            />
          )}
        />
      ))}
      
    </div>
    <div>
        <button type="submit" className="submit-button">ADD</button>
      </div>
      </div>
  </form>
);

const Coverage = () => {
  const termForm = useForm();
  const wholeLifeForm = useForm();
  const finalExpenseForm = useForm();
  const [savedCoverage, setSavedCoverage] = useState(null);

  const [termDetails, setTermDetails] = useState([]);

  const [wholeLifeDetails, setWholeLifeDetails] = useState([]);

  const [finalExpenseDetails, setFinalExpenseDetails] = useState([]);
  const navigate = useNavigate();
  const clientData = JSON.parse(localStorage.getItem('client'));
  const clientId = clientData.user_id;

  const handleRowClick = () => {
    navigate(`/details/${clientData.id}`);
  };

  const handleSubmitTermCoverage = async (data) => {
    const processedData = {
      termCoverage: {
        carrier: data.termCarrier || '',
        deathBenefit: data.termDeathBenefit || '',
        startDate: data.termStartDate || '',
        length: data.termLengthYears || ''
      },
      clientId
    };

    const updatedLoanDetails = [...termDetails, processedData];

    setTermDetails(updatedLoanDetails);



    try {

      const response = await api.post('/coverage/createTermCoverage', {

        processedData: updatedLoanDetails

      });

      setSavedCoverage(response.data); // Set the savedCoverage state

      termForm.reset(); // Clear the form data
      notifySuccess('Term Created successfully!');
     setTimeout(() => {
       navigate(`/details/${clientData.id}`);
     }, 3000);

    } catch (error) {

      console.error('Form submission error:', error);

    }

  };
  const handleSubmitWholeLifeCoverage = async (data) => {
    const processedData = {
      wholeLifeCoverage: {
        carrier: data.wholeLifeCarrier || '',
        year1: data.wholeLifeYear1 || '',
        year5: data.wholeLifeYear5 || '',
        year10: data.wholeLifeYear10 || '',
        year20: data.wholeLifeYear20 || '',
        year40: data.wholeLifeYear40 || '',
        startDate: data.wholeLifeStartDate || ''
      },
      clientId
    };
    const updatedLoanDetails = [...wholeLifeDetails, processedData];

    setWholeLifeDetails(updatedLoanDetails);



    try {

      const response = await api.post('/coverage/createWholeLifeCoverage', {

        processedData: updatedLoanDetails

      });

      setSavedCoverage(response.data); // Set the savedCoverage state

      wholeLifeForm.reset(); // Clear the form data
      notifySuccess('Whole Life Created successfully!');
     setTimeout(() => {
       navigate(`/details/${clientData.id}`);
     }, 3000);

    } catch (error) {

      console.error('Form submission error:', error);

    }

  };
  const handleSubmitFinalExpenseCoverage = async (data) => {
    const processedData = {
      finalExpenseCoverage: {
        carrier: data.finalExpenseCarrier || '',
        deathBenefit: data.finalExpenseDeathBenefit || '',
        startDate: data.finalExpenseStartDate || ''
      },
      clientId
    };


    const updatedLoanDetails = [...finalExpenseDetails, processedData];

    setFinalExpenseDetails(updatedLoanDetails);



    try {

      const response = await api.post('/coverage/createFinalExpenseCoverage', {

        processedData: updatedLoanDetails

      });

      setSavedCoverage(response.data); // Set the savedCoverage state

      finalExpenseForm.reset(); // Clear the form data
      notifySuccess('Final Expenses Created successfully!');
      setTimeout(() => {
        navigate(`/details/${clientData.id}`);
      }, 3000);

    } catch (error) {

      console.error('Form submission error:', error);

    }

  };

  const termFields = [
    { name: 'termCarrier', placeholder: 'Carrier', className: 'input-field-sub-1', rules: { required: '* Carrier is required' } },
    { name: 'termDeathBenefit', placeholder: 'Death Benefit', type: 'number', className: 'input-field-sub-2', rules: { required: '* Death Benefit is required' } },
    { name: 'termStartDate', placeholder: 'Start Date', type:'date', className: 'input-field-sub-2', rules: { required: '* Start Date is required' } },
    { name: 'termLengthYears', placeholder: 'Length (years)', type: 'number', className: 'input-field-sub-2', rules: { required: '* Length (years) is required' } },
  ];

  const wholeLifeFields = [
    { name: 'wholeLifeCarrier', placeholder: 'Carrier', className: 'input-field-sub-1', rules: { required: '* Carrier is required' } },
    { name: 'wholeLifeYear1', placeholder: 'Year 1', type: 'number', className: 'input-field-5', rules: { required: '* Year 1 is required' } },
    { name: 'wholeLifeYear5', placeholder: 'Year 5', type: 'number', className: 'input-field-5', rules: { required: '* Year 5 is required' } },
    { name: 'wholeLifeYear10', placeholder: 'Year 10', type: 'number', className: 'input-field-5', rules: { required: '* Year 10 is required' } },
    { name: 'wholeLifeYear20', placeholder: 'Year 20', type: 'number', className: 'input-field-5', rules: { required: '* Year 20 is required' } },
    { name: 'wholeLifeYear40', placeholder: 'Year 40', type: 'number', className: 'input-field-5', rules: { required: '* Year 40 is required' } },
    { name: 'wholeLifeStartDate', placeholder: 'Start Date', type:'date', className: 'input-field-sub-2', rules: { required: '* Start Date is required' } },
  ];

  const finalExpenseFields = [
    { name: 'finalExpenseCarrier', placeholder: 'Carrier', className: 'input-field-sub-1', rules: { required: '* Carrier is required' } },
    { name: 'finalExpenseDeathBenefit', placeholder: 'Death Benefit', type: 'number', className: 'input-field-sub-2', rules: { required: '* Death Benefit is required' } },
    { name: 'finalExpenseStartDate', placeholder: 'Start Date', type:'date', className: 'input-field-sub-2', rules: { required: '* Start Date is required' } },
  ];

  return (
    <Box sx={{ display: 'flex',height:'100vh' }}>
      <LeftSidenav />
      <Box component="main" className='coveragePadding' sx={{ flexGrow: 1, bgcolor: 'background.default', paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10,overflowY: 'auto' }}>
        <div>
          <h1 className="debt-name">COVERAGE</h1>
          <button type="button" className="back-button" onClick={handleRowClick}>
            BACK
          </button>
        </div>
        <FormSection
          form={termForm}
          onSubmit={handleSubmitTermCoverage}
          fields={termFields}
          title="TERM"
        />
        <FormSection
          form={wholeLifeForm}
          onSubmit={handleSubmitWholeLifeCoverage}
          fields={wholeLifeFields}
          title="WHOLE LIFE"
        />
        <FormSection
          form={finalExpenseForm}
          onSubmit={handleSubmitFinalExpenseCoverage}
          fields={finalExpenseFields}
          title="FINAL EXPENSE"
        />
      </Box>
     <ToastContainer />
    </Box>
  );
};

export default Coverage;
