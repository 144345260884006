import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Drawer,
  List,
  TextField, InputAdornment
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { ClientContext } from "../../context/ClientContext";
import './ClientsSideNav.css'
import SearchIcon from '@mui/icons-material/Search';

const ClientTable = ({ data }) => {
  const clientData = JSON.parse(localStorage.getItem("client"));
  const navigate = useNavigate();


  const { client } = useContext(ClientContext);

  const handleCreate = () => {
    navigate(`/create`);
  };
  

  return (
    <Drawer
      variant="permanent"
      classes={{ paper: "drawer-paper" }}
      sx={{
        "& .MuiDrawer-paper": {
          position: "relative",
          height: "100vh",
          background: "#DDDDDD",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box className="left-side">
          <Box className="logo-section">
            <img
              src="https://zibtek.nyc3.cdn.digitaloceanspaces.com/Mazzzing/insurality.png"
              alt="Logo"
              style={{ height: 40 }}
            />
          </Box>
          <Box sx={{ margin: '0 20px',height:'35px' }}> {/* Adjust width as needed */}
              <TextField
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: '#B0B0B0' }} /> {/* Light gray search icon */}
                    </InputAdornment>
                  ),
                  sx: {
                    backgroundColor: '#FFF', // White background for search bar
                    borderRadius: 1,
                    height: '35px'
                  }
                }}
              />
            </Box>
          

          <List sx={{padding:'20px'}}>
            <div className="row">
              <div className="leftSection">
                <span className="icon">
                  ◀
                </span>
                <span className="text">{clientData?.personal?.firstName}</span>
              </div>
                <span className="circles plus" onClick={handleCreate}></span>
            </div>
           
          </List>
        </Box>
      </Box>
    </Drawer>
  );
};

export default ClientTable;
