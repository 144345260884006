import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";  
import "./FormDesign.css"; // Ensure the path is correct
import Header from "../HeadingList/HeadingList"; // Adjust the path as needed

const AssetsIncome = ({ onDataChange, initialData }) => {
  const [data, setData] = useState({
    activeIncome: [
      { name: "", monthly: "", annual: "", incomeReplacement: false },
    ],
    passiveIncome: [{ monthly: "", annual: "", incomeReplacement: false }],
    totalHouseholdIncome: { monthly: "", annual: "", incomeReplacement: false },
    homeEquity: [{ home: "", currentValue: "", mortageProtection: false }],
    commute: [
      { name: "", oneWay: "", annual: "", accidentalDeathBenefit: false },
    ],
    savings: [
      { bank: "", monthly: "", balance: "", speciallyDesignedWholeLife: false },
    ],
    fourZeroOneK: [
      { name: "", monthly: "", balance: "", personalPensionPlan: false },
    ],
  });

  useEffect(() => {
    if (initialData) {
      setData({
        activeIncome: initialData.activeIncome.map((income) => ({
          name: income.name || "",
          monthly: income.monthly || "",
          annual: income.annual || "",
          incomeReplacement: income.incomeReplacement === "Yes",
        })),
        passiveIncome: initialData.passiveIncome.map((income) => ({
          name: income.name,
          monthly: income.monthly || "",
          annual: income.annual || "",
          incomeReplacement: income.incomeReplacement === "Yes",
        })),
        totalHouseholdIncome: {
          monthly: initialData.totalHouseholdIncome.monthly || "",
          annual: initialData.totalHouseholdIncome.annual || "",
          incomeReplacement:
            initialData.totalHouseholdIncome.incomeReplacement === "Yes",
        },
        homeEquity: initialData.homeEquity.map((equity) => ({
          home: equity.home || "",
          currentValue: equity.currentValue || "",
          mortageProtection: equity.mortageProtection === "Yes",
        })),
        commute: initialData.commute.map((commute) => ({
          name: commute.name || "",
          oneWay: commute.oneWay || "",
          annual: commute.annual || "",
          accidentalDeathBenefit: commute.accidentalDeathBenefit === "Yes",
        })),
        savings: initialData.savings.map((saving) => ({
          bank: saving.bank || "",
          balance: saving.balance || "",
          monthly: saving.monthly || "",
          speciallyDesignedWholeLife:
            saving.speciallyDesignedWholeLife === "Yes",
        })),
        fourZeroOneK: initialData.fourZeroOneK.map((fourZeroOneK) => ({
          name: fourZeroOneK.name || "",
          monthly: fourZeroOneK.monthly || "",
          balance: fourZeroOneK.balance || "",
          personalPensionPlan: fourZeroOneK.personalPensionPlan === "Yes",
        })),
      });
    }
  }, [initialData]);

  useEffect(() => {
    const payload = {
      activeIncome: data.activeIncome.map((income) => ({
        name: income.name,
        monthly: income.monthly ? parseFloat(income.monthly) : "",
        annual: income.annual ? parseFloat(income.annual) : "",
        incomeReplacement: income.incomeReplacement ? "Yes" : "No",
      })),
      passiveIncome: data.passiveIncome.map((income) => ({
        name: income.name,
        monthly: income.monthly ? parseFloat(income.monthly) : "",
        annual: income.annual ? parseFloat(income.annual) : "",
        incomeReplacement: income.incomeReplacement ? "Yes" : "No",
      })),
      totalHouseholdIncome: {
        monthly: data.totalHouseholdIncome.monthly
          ? parseFloat(data.totalHouseholdIncome.monthly)
          : "",
        annual: data.totalHouseholdIncome.annual
          ? parseFloat(data.totalHouseholdIncome.annual)
          : "",
        incomeReplacement: data.totalHouseholdIncome.incomeReplacement
          ? "Yes"
          : "No",
      },
      homeEquity: data.homeEquity.map((equity) => ({
        home: equity.home,
        currentValue: equity.currentValue
          ? parseFloat(equity.currentValue)
          : "",
        mortageProtection: equity.mortageProtection ? "Yes" : "No",
      })),
      commute: data.commute.map((commute) => ({
        name: commute.name,
        oneWay: commute.oneWay ? parseFloat(commute.oneWay) : "",
        annual: commute.annual ? parseFloat(commute.annual) : "",
        accidentalDeathBenefit: commute.accidentalDeathBenefit ? "Yes" : "No",
      })),
      savings: data.savings.map((saving) => ({
        bank: saving.bank,
        monthly: saving.monthly ? parseFloat(saving.monthly) : "",
        balance: saving.balance ? parseFloat(saving.balance) : "",
        speciallyDesignedWholeLife: saving.speciallyDesignedWholeLife
          ? "Yes"
          : "No",
      })),
      fourZeroOneK: data.fourZeroOneK.map((fourZeroOneK) => ({
        name: fourZeroOneK.name,
        monthly: fourZeroOneK.monthly ? parseFloat(fourZeroOneK.monthly) : "",
        balance: fourZeroOneK.balance ? parseFloat(fourZeroOneK.balance) : "",
        personalPensionPlan: fourZeroOneK.personalPensionPlan ? "Yes" : "No",
      })),
    };

    onDataChange(payload);
  }, [data, onDataChange]);

  const handleChange = (e, index, category, name) => {
    const { value, type, checked } = e.target;

    setData((prevData) => ({
      ...prevData,
      [category]: Array.isArray(prevData[category])
        ? prevData[category].map((item, i) =>
            i === index
              ? { ...item, [name]: type === "checkbox" ? checked : value }
              : item
          )
        : {
            ...prevData[category],
            [name]: type === "checkbox" ? checked : value,
          }, // If it's an object, update it directly
    }));
  };

  // const handleChange = (index, name, value, type = 'text') => {
  //   setData(prevData => ({
  //     ...prevData,
  //     [name]: prevData[name].map((item, i) =>
  //       i === index ? { ...item, [type]: value } : item
  //     ),
  //   }));
  // };

  const handleCheckboxChange = (e, index, category, name) => {
    const { checked } = e.target;
  
    setData((prevData) => ({
      ...prevData,
      [category]: prevData[category].map((item, i) =>
        i === index ? { ...item, [name]: checked } : item
      ),
    }));
  };

  const addNewEntry = (type) => {
    setData(prevData => ({
      ...prevData,
      [type]: [...prevData[type], { name: '', age: '', lifeInsurance: false }],
    }));
  };

  const deleteEntry = (index, type) => {
    setData(prevData => ({
      ...prevData,
      [type]: prevData[type].filter((_, i) => i !== index),
    }));
  };

  return (
    <>
      <Header name="ASSETS / INCOME" />

      <Box sx={{ padding: 2,marginBottom: 6 }}>

      {data.activeIncome.map((income, index) => (
        <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1,flexWrap:'nowrap' }} key={index}>
          <Grid  xs={12} sm={2} className="responsiveWidth">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton color="primary" sx={{padding:'1px',color:'#24527B'}} onClick={() => addNewEntry('activeIncome')}>
                <AddIcon className="icons" />
              </IconButton>
              <Typography variant="body1" className="spouseTypo">
                Active Income
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
            <div className="grid-item">
              <input
                type="text"
                placeholder="Name"
                value={income.name}
                onChange={(e) =>
                  handleChange(e,index, 'activeIncome', 'name')
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Monthly"
                value={income.monthly}
                onChange={(e) =>
                  handleChange(e,index, 'activeIncome', 'monthly')
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Annual"
                value={income.annual}
                onChange={(e) =>
                  handleChange(e,index, 'activeIncome', 'annual')
                }
              />
            </div>

            {/* Add Checkbox */}
            <div className="grid-item checkboxItem">
              <FormControlLabel sx={{whiteSpace:'nowrap'}}
                control={
                  <Checkbox
                    checked={income.incomeReplacement}
                    onChange={(e) =>
                      handleCheckboxChange(e, index, 'activeIncome', 'incomeReplacement')
                    }
                    sx={{
                      marginRight: 2,
                      boxShadow: '3px 3px 1px gray',
                      border: 'none',
                      borderRadius: 0,
                      width: 16,
                      height: 16,
                      color: 'gray',
                      backgroundColor: 'transparent',
                      '& .MuiSvgIcon-root': {
                        fill: 'gray',
                      },
                    }}
                  />
                  
                }
                label="Income Replacement"
              />
              {data.activeIncome.length > 1 && (
                <IconButton color="#EFEFEF" className="iconFix" onClick={() => deleteEntry(index, 'activeIncome')}>
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          </div>
        </Grid>
      ))}


      {data.passiveIncome.map((income, index) => (
        <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1,flexWrap:'nowrap' }} key={index}>
          <Grid  xs={12} sm={2} className="responsiveWidth">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton color="primary" sx={{padding:'1px',color:'#24527B'}} onClick={() => addNewEntry('passiveIncome')}>
                <AddIcon className="icons" />
              </IconButton>
              <Typography variant="body1" className="spouseTypo">
                Passive Income
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
            <div className="grid-item">
              <div className="emptyInput"></div> {/* Adjust width as needed */}
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Monthly"
                value={income.monthly}
                onChange={(e) =>
                  handleChange(e,index, 'passiveIncome', 'monthly')
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Annual"
                value={income.annual}
                onChange={(e) =>
                  handleChange(e,index, 'passiveIncome', 'annual')
                }
              />
            </div>

            {/* Add Checkbox */}
            <div className="grid-item checkboxItem">
              <FormControlLabel
              sx={{whiteSpace:'nowrap'}}
                control={
                  <Checkbox
                    checked={income.incomeReplacement}
                    onChange={(e) =>
                      handleCheckboxChange(e, index, 'passiveIncome', 'incomeReplacement')
                    }
                    sx={{
                      marginRight: 2,
                      boxShadow: '3px 3px 1px gray',
                      border: 'none',
                      borderRadius: 0,
                      width: 16,
                      height: 16,
                      color: 'gray',
                      backgroundColor: 'transparent',
                      '& .MuiSvgIcon-root': {
                        fill: 'gray',
                      },
                    }}
                  />
                  
                }
                label="Income Replacement"
              />
              {data.passiveIncome.length > 1 && (
                <IconButton color="#EFEFEF" className="iconFix" onClick={() => deleteEntry(index, 'passiveIncome')}>
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          </div>
        </Grid>
      ))}


        <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1,flexWrap:'nowrap' }}>
        <Grid  xs={12} md={2} className="responsiveWidth">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1" component="span" className="totalAlign">
                Total Household Income
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
            <div className="grid-item">
              <div className="emptyInput"></div> {/* Adjust width as needed */}
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Monthly"
                value={data.totalHouseholdIncome?.monthly}
                onChange={(e) =>
                  handleChange(e, 0,'totalHouseholdIncome', 'monthly')
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Annual"
                value={data.totalHouseholdIncome?.annual}
                onChange={(e) =>
                  handleChange(e, 0,'totalHouseholdIncome', 'annual')
                }
              />
            </div>

            {/* Add Checkbox */}
            <div className="grid-item checkboxItem">
              <FormControlLabel sx={{whiteSpace:'nowrap'}}
                control={
                  <Checkbox
                    checked={data.totalHouseholdIncome?.incomeReplacement}
                    onChange={(e) =>
                      handleChange(e, 0, 'totalHouseholdIncome', 'incomeReplacement')
                    }
                    sx={{
                      marginRight: 2,
                      boxShadow: '3px 3px 1px gray',
                      border: 'none',
                      borderRadius: 0,
                      width: 16,
                      height: 16,
                      color: 'gray',
                      backgroundColor: 'transparent',
                      '& .MuiSvgIcon-root': {
                        fill: 'gray',
                      },
                    }}
                  />
                  
                }
                label="Income Replacement"
              />
            </div>
          </div>
          </Grid>


        {data.homeEquity.map((equity, index) => (
          <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1,flexWrap:'nowrap' }} key={index}>
            <Grid  xs={12} sm={2} className="responsiveWidth">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton color="primary" sx={{padding:'1px',color:'#24527B'}} onClick={() => addNewEntry('homeEquity')}>
                  <AddIcon className="icons" />
                </IconButton>
                <Typography variant="body1" className="spouseTypo">
                    Home Equity
                </Typography>
              </Box>
            </Grid>

            <div className="grid-container">
              <div className="grid-item">
                <input
                  type="text"
                  placeholder="Home"
                  value={equity.home}
                  onChange={(e) =>
                    handleChange(e,index, 'homeEquity', 'home')
                  }
                />
              </div>

              <div className="grid-item">
                <div className="emptyInput"></div> {/* Adjust width as needed */}
              </div>

              <div className="grid-item">
                <input
                  type="text"
                  placeholder="Current Value"
                  value={equity.currentValue}
                  onChange={(e) =>
                    handleChange(e,index, 'homeEquity', 'currentValue')
                  }
                />
              </div>

              {/* Add Checkbox */}
              <div className="grid-item checkboxItem">
                <FormControlLabel
                sx={{whiteSpace:'nowrap'}}
                  control={
                    <Checkbox
                      checked={equity.mortageProtection}
                      onChange={(e) =>
                        // handleCheckboxChange(index, 'homeEquity', e.target.checked)
                      handleCheckboxChange(e, index, 'homeEquity', 'mortageProtection')

                      }
                      sx={{
                        marginRight: 2,
                        boxShadow: '3px 3px 1px gray',
                        border: 'none',
                        borderRadius: 0,
                        width: 16,
                        height: 16,
                        color: 'gray',
                        backgroundColor: 'transparent',
                        '& .MuiSvgIcon-root': {
                          fill: 'gray',
                        },
                      }}
                    />
                    
                  }
                  label="Mortgage Protection"
                />
                {data.homeEquity.length > 1 && (
                  <IconButton color="#EFEFEF" className="iconFix" onClick={() => deleteEntry(index, 'homeEquity')}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            </div>
          </Grid>
        ))}

      {data.commute.map((commute, index) => (
        <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1,flexWrap:'nowrap' }} key={index}>
          <Grid  xs={12} sm={2} className="responsiveWidth">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton color="primary" sx={{padding:'1px',color:'#24527B'}} onClick={() => addNewEntry('commute')}>
                <AddIcon className="icons" />
              </IconButton>
              <Typography variant="body1" className="spouseTypo">
                Commute(Miles)
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
            <div className="grid-item">
              <input
                type="text"
                placeholder="Name"
                value={commute.name}
                onChange={(e) =>
                  handleChange(e,index, 'commute', 'name')
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="One Way"
                value={commute.oneWay}
                onChange={(e) =>
                  handleChange(e,index, 'commute', 'oneWay')
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Annual"
                value={commute.annual}
                onChange={(e) =>
                  handleChange(e,index, 'commute', 'annual')
                }
              />
            </div>

            {/* Add Checkbox */}
            <div className="grid-item checkboxItem">
              <FormControlLabel
                sx={{whiteSpace:"nowrap"}}
                control={
                  <Checkbox
                    checked={commute.accidentalDeathBenefit}
                    onChange={(e) =>
                      // handleCheckboxChange(index, 'commute', e.target.checked)
                      handleCheckboxChange(e, index, 'commute', 'accidentalDeathBenefit')
                    }
                    sx={{
                      marginRight: 2,
                      boxShadow: '3px 3px 1px gray',
                      border: 'none',
                      borderRadius: 0,
                      width: 16,
                      height: 16,
                      color: 'gray',
                      backgroundColor: 'transparent',
                      '& .MuiSvgIcon-root': {
                        fill: 'gray',
                      },
                    }}
                  />
                  
                }
                label="Accidental Death Benefit"
              />
              {data.commute.length > 1 && (
                  <IconButton color="#EFEFEF" className="iconFix" onClick={() => deleteEntry(index, 'commute')}>
                    <DeleteIcon />
                  </IconButton>
                )}
            </div>
          </div>
        </Grid>
      ))}

      {data.savings.map((saving, index) => (
        <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1,flexWrap:'nowrap' }} key={index}>
          <Grid  xs={12} sm={2} className="responsiveWidth">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton color="primary" sx={{padding:'1px',color:'#24527B'}} onClick={() => addNewEntry('savings')}>
                <AddIcon className="icons" />
              </IconButton>
              <Typography variant="body1" className="spouseTypo">
                Savings
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
            <div className="grid-item">
              <input
                type="text"
                placeholder="Name"
                value={saving.bank}
                onChange={(e) =>
                  handleChange(e,index, 'savings', 'bank')
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Monthly"
                value={saving.monthly}
                onChange={(e) =>
                  handleChange(e,index, 'savings', 'monthly')
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Annual"
                value={saving.balance}
                onChange={(e) =>
                  handleChange(e,index, 'savings', 'balance')
                }
              />
            </div>

            {/* Add Checkbox */}
            <div className="grid-item checkboxItem">
              <FormControlLabel
                sx={{whiteSpace:"nowrap"}}
                control={
                  <Checkbox
                    checked={saving.speciallyDesignedWholeLife}
                    onChange={(e) =>
                      // handleCheckboxChange(index, 'savings', e.target.checked)
                      handleCheckboxChange(e, index, 'savings', 'speciallyDesignedWholeLife')
                    }
                    sx={{
                      marginRight: 2,
                      boxShadow: '3px 3px 1px gray',
                      border: 'none',
                      borderRadius: 0,
                      width: 16,
                      height: 16,
                      color: 'gray',
                      backgroundColor: 'transparent',
                      '& .MuiSvgIcon-root': {
                        fill: 'gray',
                      },
                    }}
                  />
                  
                }
                label="Specially Designed Whole Life"
              />
              {data.savings.length > 1 && (
                  <IconButton color="#EFEFEF" className="iconFix" onClick={() => deleteEntry(index, 'saving')}>
                    <DeleteIcon />
                  </IconButton>
                )}
            </div>
          </div>
        </Grid>
      ))}


      {data.fourZeroOneK.map((k401, index) => (
        <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1 ,flexWrap:'nowrap'}} key={index}>
          <Grid  xs={12} sm={2} className="responsiveWidth">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton color="primary" sx={{padding:'1px',color:'#24527B'}} onClick={() => addNewEntry('fourZeroOneK')}>
                <AddIcon className="icons" />
              </IconButton>
              <Typography variant="body1" className="spouseTypo">
                Passive k401
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
            <div className="grid-item">
              <input
                type="text"
                placeholder="Name"
                value={k401.name}
                onChange={(e) =>
                  handleChange(e,index, 'fourZeroOneK', 'name')
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Monthly"
                value={k401.monthly}
                onChange={(e) =>
                  handleChange(e,index, 'fourZeroOneK', 'monthly')
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Balance"
                value={k401.balance}
                onChange={(e) =>
                  handleChange(e,index, 'fourZeroOneK', 'balance')
                }
              />
            </div>

            {/* Add Checkbox */}
            <div className="grid-item checkboxItem">
              <FormControlLabel
                sx={{whiteSpace:"nowrap"}}
                control={
                  <Checkbox
                    checked={k401.personalPensionPlan}
                    onChange={(e) =>
                      // handleCheckboxChange(index, 'fourZeroOneK', e.target.checked)
                      handleCheckboxChange(e, index, 'fourZeroOneK', 'personalPensionPlan')
                    }
                    sx={{
                      marginRight: 2,
                      boxShadow: '3px 3px 1px gray',
                      border: 'none',
                      borderRadius: 0,
                      width: 16,
                      height: 16,
                      color: 'gray',
                      backgroundColor: 'transparent',
                      '& .MuiSvgIcon-root': {
                        fill: 'gray',
                      },
                    }}
                  />
                  
                }
                label="Personal Pension Plan"
              />
              {data.fourZeroOneK.length > 1 && (
                  <IconButton color="#EFEFEF" className="iconFix" onClick={() => deleteEntry(index, 'fourZeroOneK')}>
                    <DeleteIcon />
                  </IconButton>
                )}
            </div>
          </div>
        </Grid>
      ))}
      </Box>
    </>
  );
};

export default AssetsIncome;
